<template>
    <div v-if="topicName == 'Arbejdsmarked & Beskæftigelse'">
        <!-- 0 -->
        <p>Beskæftigelsespolitik handler om forholdene på og uden for arbejdsmarkedet. Dette område vedrører derfor både personer i arbejde og dem, som står uden for arbejdsmarkedet. I Danmark har vi den såkaldte danske model, som sikrer arbejdsgivernes ret til at fordele og lede arbejdet, og fagforeningernes ret til at forhandle de ansattes løn- og arbejdsvilkår. I trepartsforhandlingerne indgår arbejdsgiverne, fagforeningerne og staten aftaler, som vedrører arbejdsmarked. </p>
        <p>Men arbejdsmarkeds- og beskæftigelsespolitik dækker også over forhold uden for arbejdsmarkedet. Hvem har ret til kontanthjælp, hvornår kan man få sygedagpenge, og hvor høj skal pensionsalderen være?</p>
        <p>Bliv klogere på politikernes holdninger til arbejdsmarked og beskæftigelse, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'By & Bolig'">
        <!-- 1 -->
        <p>By- og boligpolitik handler om at gøre hele Danmark til et attraktivt sted at bo og leve for alle. Flere og flere ønsker at bo i de større byer, hvilket presser boligmarkedet med stigende boligpriser og boligmangel til følge. Samtidig rammes landsbyer af manglende tilflytning, lukning af butikker og institutioner samt af at realkreditinstitutioner er tilbageholdende med at låne penge til mulige købere. Derudover er diskussionen om ghettoerne også stigende i disse år. By- og boligpolitik handler blandt andet om, hvordan vi tackler disse problemer og mange flere. </p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Børn, Unge & Familie'">
        <!-- 2 -->
        <p>Der er store diskussioner om, hvad dagens børne-, unge- og familiepolitik handler om, og ikke mindst hvad det bør handle om. Overordnet set handler børne- og ungepolitik om børn og unges rettigheder. Hvordan sikrer vi børn og unges trivsel? Hvordan hjælper vi udsatte børn og unge og skaber stabilitet og tryghed for børn, som ikke kan bo hjemme hos deres forældre?</p>
        <p>Samtidig behandler børne-, unge- og familiepolitikken også værdimæssige spørgsmål. Hvad er et godt familieliv? Hvad er det vigtigste for børn: leg, læring eller noget tredje? Arbejder forældre for meget – og er curlingforældre en god eller dårlig ting? Børne- og ungepolitik går på tværs af mange emner, men har konstant børn og unges rettigheder i fokus.</p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Klima, Energi & Forsyning'">
        <!-- 3 -->
        <p>Danmark fik sit første klimaministerium i 2007. Siden da er energi- og klimaområdet blevet samlet i ét ministerium. Energi, forsyning og klima hænger tæt sammen. Det er gennem omstillingen og udviklingen af vores forsyningssektor, at vi kan tage hånd om vores klima, men også der, hvor vi i høj grad kan skade klimaet. Klimaforandringerne har de sidste år været et brandvarmt emne både i dansk politik og på den internationale scene. </p>
        <p>Men hvordan har politikerne egentlig tænkt sig at takle klimaudfordringerne? Gå på opdagelse i debatten i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'EU'">
        <!-- 4 -->
        <p>Danmark har været en del af Den Europæiske Union siden 1972. Spørgsmål vedrørende Danmarks rolle i Unionen, implementering af EU-lovgivning og Danmarks suverænitet er ofte til debat. Senest i december 2015, hvor det danske retsforbehold var til folkeafstemning. Det kan være svært at følge med i debatten om EU og finde ud af, hvordan beslutninger i Bruxelles påvirker hverdagen i Danmark. Men den politik som vedtages i EU, kan have stor betydning for den danske lovgivning.</p>
        <p>Er du også forvirret, så gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Finanslov & offentlige finanser'">
        <!-- 5 -->
        <p>Hvert år skal en ny finanslov for den offentlige økonomi forhandles. Her fastlægges statens udgifter og de enkle ministeriers budgetter for det næste finansår. Finanslov og offentlige finanser er et vigtigt politisk område, da det er her, Folketinget beslutter, hvad der skal bruges penge på – og hvor der skal spares. Finansministeriet er også et af de, hvis ikke det – mest magtfulde ministerier. </p>
        <p>Finansåret følger kalenderåret. Regeringens udspil til finansloven præsenteres i august, og forhandlingerne starter i oktober. I december skal finansloven vedtages. Sker dette ikke, må regeringen træde tilbage og udskrive valg. Finansloven er derfor afgørende for regeringens grundlag.</p>
        <p>Gå på opdagelse i, hvordan finansloven bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Erhverv & Finans'">
        <!-- 6 -->
        <p>Erhvervspolitik handler i høj gad om vækst og virksomheders trivsel. Hvordan kan vi få mere vækst i Danmark? Hvordan får danske virksomheder en bedre konkurrenceevne? Hvilke brancher skal vi særligt satse på i Danmark? </p>
        <p>Erhvervspolitik handler også om finanssektoren, altså pengeinstitutter, realkreditinstitutter og pensionsselskaber. Efter finanskrisen i 2008 har der været øget fokus på finanssektorens virke og råderum. Men hvordan ser alt dette ud ti år efter krisen?</p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Flygtninge & Indvandrere'">
        <!-- 7 -->
        <p>Dette emne er højaktuelt. Nogen vil endda kalde det et politisk sprængfarligt emne, som i den grad skiller partierne og måske ligefrem kan afgøre valgkampe. På trods af at flygtninge og indvandrere har fyldt meget i dansk politik i mange år, så blev det i 2015 endnu tydeligere, da den såkaldte flygtningekrise for alvor kom til Danmark med billeder af flygtninge, der løb over markerne ved Rødby Færge.</p>
        <p>Emnet kan opdeles i to underemner: 1) flygtninge og asylpolitik og 2) indvandrere og integrationspolitik. Flygtninge og asylpolitik handler om, hvordan vi håndterer flygtninge og asylansøgere. I den fase, hvor flygtninge søger om asyl, kaldes de asylansøgere. Hvis de opnår asyl og dermed får en opholdstilladelse, kaldes de flygtninge. Hvis ikke de opnår asyl, skal de sendes tilbage til deres hjemland.</p>
        <p>Indvandrere og integrationspolitik handler om, hvad der skal til for at blive dansk statsborger eller at få opholdstilladelse. Og hvad med familiesammenføring? Er der nogle bestemte udlændinge, som vi særligt ønsker til Danmark, for eksempel højtuddannede, som nemmere integreres på det danske arbejdsmarked? Og hvad skal der til for, at integrationen lykkes? Skal man straffe udlændinge, som ikke opfører sig, som man ønsker, eller skal man snarere prøve at fremme forskellighed og mangfoldighed?</p>
        <p>Hvert år kommer mange flygtninge og udlændinge til Danmark, og det giver store politiske diskussioner. Gå på opdagelse i, hvordan området bliver debatteret i Folketinget &ndash; og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Kultur, Fritid & Tro'">
        <!-- 13 -->
        <p>Kultur, fritid og tro handler om kulturelle områder som museer, teatre, bygningsværker og Danmarks Radio (DR) samt forskellige trossamfund i Danmark. </p>
        <p>Kulturpolitik handler om den danske kulturarv, kulturelle tilbud og public service. De store politiske diskussioner på kulturområdet handler ofte om medieforliget, som lægger de økonomiske rammer for danske medier. Fritidspolitik handler i høj grad om idræt og om den økonomiske ramme for idrætsorganisationerne. </p>
        <p>I Danmark er kristendommen, nærmer bestemt den evangelisk-lutherske kristendom, statsreligion. Der findes dog mange andre trossamfund i Danmark. Kirkeministeriet tager sig af alle anerkendte trossamfund i Danmark og står for eksempel for vielsesbemyndigelser og tilladelser til anlæggelse af gravpladser.</p>
        <p>Gå på opdagelse i, hvordan kultur, fritid og tro debatteres i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Forsvar & Militær'">
        <!-- 9 -->
        <p>Forsvar og militær handler om Danmarks forsvarsstyrker i ind- og udland. Danmark har været medlem af FN siden 1945 og Nato siden 1949. I løbet af de sidste årtier har Danmark ført en aktivistisk udenrigspolitik og sendt soldater til flere lande i Mellemøsten og deltaget i internationale missioner i Kosovo, Afghanistan, Irak og Libyen. Senest har Danmark sendt kampfly til Syrien. Det danske forsvar har desuden været en aktiv spiller i at bekæmpe pirateri i Somalia og tager også del i fredsbevarende styrker under FN. </p>
        <p>Forsvarets opgaver i Danmark spænder også vidt. Forsvaret står blandt andet for eftersøgning, redning og ambulanceflyvning. Forsvaret står også for forureningsbekæmpelse til søs. Og så har Forsvaret ansvar for suverænitetshævdelse og overvågning. Forsvaret dækker over Hæren, Søværnet og Flyvevåbnet, men under området ”Forsvar og militær” medregnes også Hjemmeværnet.</p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Fødevarer'">
        <!-- 10 -->
        <p>Fødevarepolitik handler om produktion og kvalitetssikring af fødevarer produceret i og uden for Danmark. Landbrug og fiskeri er to af de vigtigste bidrag til fødevareproduktion i Danmark. Lovgivning inden for dette område kan for eksempel handle om fiskekvoter, randområder eller økologi. Men fødevarepolitik handler også om sundhed. Hvordan gør vi det lettere for forbrugerne at aflæse fødevaredeklarationer? Hvilke former for emballage kan indeholde skadelige stoffer? Og hvordan gør vi økologi billigere?</p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Kommuner & Regioner'">
        <!-- 12 -->
        <p>I Danmark er der tre vigtige myndigheder, som tager sig af landets styring: kommuner, regioner og staten. Kommunerne tager sig af de primære borgerrettede opgaver som børnepasning, folkeskoler, ældrepleje m.m. Regionernes største opgave er at drive det danske sygehusvæsen. De sørger for, at sygehuse og psykiatrien fungerer. Staten bestemmer de overordnede rammer, lovgivning og økonomi, som kommunerne og regionerne skal arbejde indenfor. </p>
        <p>Gå på opdagelse i, hvordan kommuner og regioner debatteres i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Ligestilling & Minoritetsrettigheder'">
        <!-- 14 -->
        <p>Ligestilling og minoritetsrettigheder handler om at sikre rettigheder og bekæmpe diskrimination på baggrund af køn, seksualitet, etnicitet og funktionsevne m.m. Hvordan skaber vi et arbejdsmarked, hvor alle har lige adgang? Skal mænd have øremærket barsel? Hvordan sikrer vi LGBTI+-personers rettigheder og bekæmper hadforbrydelser? Hvordan bliver det offentlige rum mere tilgængeligt for personer med funktionsnedsættelser? Disse spørgsmål er alle vigtige i ligestillingsdebatten.</p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Miljø & Natur'">
        <!-- 15 -->
        <p>Miljøpolitik handler om den danske natur og de nære miljømæssige problematikker. Hvordan passer vi bedst på den danske natur? Hvordan sikrer vi rent grundvand eller nedbringer luftforureningen i byerne? Hvor meget vild natur skal vi have i Danmark? I modsætning til Energi, forsyning og klima omhandler Miljø- og naturområdet de nære miljømæssige forhold med det formål om at sikre, at dyreliv, skovområder, årer og vandløb bevares i balance.</p>
        <p>Gå på opdagelse i hvordan miljøpolitik bliver debatteret i Folketinget - og husk at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Offentlig forvaltning'">
        <!-- 16 -->
        <p>Området offentlig forvaltning handler om, hvilke rammer vi ønsker for den måde, offentlige myndigheder agerer på. Offentlig forvaltning handler meget om forvaltningsloven, men også om tilstødende emner som eksempelvis hvordan offentlige myndigheder skal forholde sig til whistleblowing og aktindsigter. De senere år har der været store diskussioner om offentlighedsloven, som blev vedtaget i 2013. Især § 24 har været genstand for kritik fra flere sider og er blandt andet blevet kaldt en alarmerende indskrænkning af pressens muligheder for at kontrollere statsmagten og gå ministrene efter i sømmene.</p>
        <p>Gå på opdagelse i, hvad der sker indenfor den offentlige forvaltning, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Retspolitik & Justitsvæsen'">
        <!-- 17 -->
        <p>Retspolitik handler blandt andet om straffeloven, domstolene og politiet. Her kan emner som strafferammen for forskellige typer af kriminalitet og den kriminelle lavalder blive diskuteret. Et emne, der har fyldt meget de senere år, er blandt andet de lovgivningsmæssige rammer for voldtægtssager og hævnporno. Et andet stort emne er, hvordan vi skal straffe kriminelle rockere og bandemedlemmer. Terror er også et emne, der siden terrorangrebet 11. september 2001 har optaget politikerne.</p>
        <p>Men retspolitik handler også om, hvordan politiet, politiets efterretningstjeneste (PET) og kriminalforsorgen (fængslerne) skal agere, hvilke opgaver de skal have særligt fokus på og hvor mange penge de har til at løse deres opgaver.</p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Rigsfællesskabet'">
        <!-- 18: won't load -->
        <p>Rigsfællesskabet er navnet for den administrative opdeling mellem Danmark, Grønland og Færøerne. Selvom Færøerne siden 1948 har haft hjemmestyre og Grønland siden 1979, så er de begge stadig en del af kongeriget Danmark og er dermed ikke selvstændige stater. Det betyder, at de tre lande er samlet i én stat, men at Grønland og Færøerne selv lovgiver og administrerer flere sagsområder. Derudover har de to lande hver to repræsentanter i Folketinget. Det kan være meget forskellige ting, der politisk set fylder i rigsfællesskabet. Men de senere år har særligt spørgsmålet om, hvad der skal ske med Arktis spillet en stor rolle.</p>
        <p>Gå på opdagelse i rigsfællesskabet, og se, hvordan området debatteres i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Skatter & Afgifter'">
        <!-- 19 -->
        <p>Skattepolitik handler om statens indtægter – det vil sige skatter, moms og afgifter. Det danske velfærdssystem er i høj grad finansieret gennem skat, hvilket også er grunden til, at vi i Danmark har et relativt højt skattetryk. Skattelettelser er også en af de diskussioner, som ofte bliver taget op i forbindelse med finansloven. Hvor dyre skal biler være? Og hvad med boligskatterne? Kan man sætte momsen ned på økologiske varer? Er det en god idé at indføre afgifter på sukker og fedt? Og hvor skal udenlandsdanskere betale deres skat?</p>
        <p>Gå på opdagelse i dansk skattepolitik, og se, hvordan emnet debatteres i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Socialområdet'">
        <!-- 20 -->
        <p>Socialpolitik handler grundlæggende om, hvordan vi skal forebygge og håndtere socialt udsatte og sårbare grupper i Danmark. Det at være socialt udsat kan skyldes forskellige ting og komme til udtryk på mange måder. Det spænder over hjemløshed, misbrugere, sexarbejdere og voldsramte personer. Men også grupper som unge mødre og selvskadende personer bliver betragtet som en udsat og sårbar gruppe. Fælles for udsatte grupper er, at det er personer eller grupper af mennesker der har vanskeligt ved at forsørge sig selv.</p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Sundhed'">
        <!-- 21 -->
        <p>Sundhedspolitik handler om befolkningens sundhed. Hvordan skal vores sundhedsvæsen indrettes – skal der være supersygehuse eller sygehuse tæt på lokalbefolkningen? Skal det være offentlige eller private hospitaler, og hvor meget må de koste? Hvor lang tid må der gå, inden man har krav på behandling? Skal nogen sygdomme have særlig opmærksomhed? Og skal det være lovligt at benytte medicinsk cannabis?</p>
        <p>Dét og meget mere er spørgsmål, som debatteres i sundhedspolitik. Gå på opdagelse i dansk sundhedspolitik, og husk, at du kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Teknologi, IT & Digitalisering'">
        <!-- 22 -->
        <p>Vi lever i en digital tid, hvor flere og flere aspekter af vores liv afhænger af internettet og digital teknologi. Den teknologiske udvikling har gjort mange ting lettere og mere effektive. Men vi risikerer også at tabe nogle i farten – og gå på kompromis med datasikkerheden. Særligt ældre borgere kan have svært ved at navigere i de nye digitale systemer. Hvordan sikrer vi, at alle borgere får gavn af den digitale udvikling? Hvordan sikrer vi, at vores systemer ikke bliver hacket, og at vi ikke mister personfølsomme data såsom CPR-numre? Og hvordan kan Danmark gå med forrest i den teknologiske udvikling? </p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Transport & Infrastruktur'">
        <!-- 23 -->
        <p>Den danske infrastruktur er afgørende for at kunne forbinde forskellige dele af landet. Hver dag pendler store dele af befolkningen, og de er derfor afhængige af, at bus og togtrafikken fungerer optimalt. Men infrastruktur handler også om placeringen af nye motorveje, nedlæggelse af færgeruter og opførelse af nye broer. Hvordan forbinder vi bedst de danske øer med resten af landet, og hvad skal den offentlige transport koste? Skal vi indføre el- eller magnettog? Der er nogle af de spørgsmål, politikerne skal tage stilling til.</p>
        <p>Gå på opdagelse i transport og infrastruktur, og bliv klogere på hvordan området debatteres i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Uddannelse & Forskning'">
        <!-- 24 -->
        <p>Uddannelse og forskning dækker her over alt, der har med undervisning, uddannelse, innovation og forskning at gøre. Folkeskolen, ungdomsuddannelser, universiteter, forskerparker og forskningscentre hører alle under emnet Uddannelse og Forskning. </p>
        <p>Gå på opdagelse i, hvordan området bliver debatteret i Folketinget, og husk, at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
    <div v-else-if="topicName == 'Udenrigspolitik'">
        <!-- 25 -->
        <p>Udenrigspolitik handler overordnet om Danmarks relation til og adfærd over for andre lande. Formålet med udenrigspolitik er at sikre Danmarks interesser i det internationale system. Det indebærer blandt andet handel, internationale samarbejder om for eksempel klimapolitik, sikkerhedspolitik, ulandsbistand og ikke mindst det økonomiske samarbejde i EU. Men områder som flygtningepolitik fylder i stigende grad også i dansk udenrigspolitik. Udenrigspolitik er derfor et meget bredt område. </p>
        <p>Gå på opdagelse i hvordan området bliver debatteret i Folketinget - og husk at du altid kan se udviklingen over tid ved at skifte regeringsperiode.</p>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

p {
  font-family: "roboto-condensed";
  font-weight: 500;
  font-size: 120%;
  color: #000;
  text-align: justify;
}

@media (max-width: $bp-narrow) {
  p {
    font-size: 150%;
  }
}
</style>

<script>
export default {
  props: ["topicName"],
  data() {
    return {};
  }
};
</script>

<template>
  <div class="outer-container" :class="customClasses">
    <div class="inner-container" :class="whiteText?'white-text':''">
      <h1 class="title title">
        <slot name="title"></slot>
      </h1>
      <br />
      <p class="subtitle">
        <slot name="subtitle"></slot>
      </p>
      <p class="topic">
        <slot name="topic"></slot>
      </p>
      <span v-if="quotes" class="quotes">“</span>

    </div>
    <div class="down-text" v-if="downText">
      <p>{{downText}}</p>
      <img v-if="whiteText" src="@/assets/icons/down-white.svg" alt="" width="5%">
      <img v-else src="@/assets/icons/down-black.svg" alt="" width="5%">
  </div>
    </div>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.bg-lightgreen {
  background: $color-lightgreen;
}

.bg-blue {
  background: $color-blue;
}

.bg-lightblue {
  background: $color-lightblue;
}

.bg-red {
  background: $color-red;
}

.bg-yellow {
  background: $color-yellow;
}

.bg-white {
  background: white;
}

.bg-orange {
  background: $color-orange;
}

.bg-turquoise {
  background: $color-turquoise;
}

.white-text {
  color: white;
}

.outer-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
.inner-container {
  margin-left: 10%;
  width: 65%;
  position: relative;
}

.quotes {
  position: absolute;
  top: -20%;
  left: 0;
  font-size: 900%;
  font-family: "Times New Roman", Times, serif;
}

.title {
  font-size: 250%;
}

.subtitle,
.topic {
  font-family: "roboto-condensed";
  font-weight: 500;
  font-size: 150%;
  line-height: 1.5em;
  color: #000;
}
.down-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  p {
    margin: 0;
  }
  img {
    width: 35px;
  }
}

@media (max-width: $bp-narrow) {
  .inner-container {
    width: 80%;
  }
}
</style>

<script>
export default {
  props: {
    quotes: {
      default: false
    },
    background: {
      default: false
    },
    whiteText: {
      default: false
    },
    downText: {
      default: false
    }
  },
  computed: {
    customClasses: function() {
      let customClass = "";
      if (this.background) {
        customClass += `bg-${this.background}`;
      }
      if (this.whiteText) {
        customClass += " white-text";
      }
      return customClass;
    }
  }
};
</script>

<template>
  <div class="chart-holder">
    <h3>Så meget nævner partiene {{keywords.toLowerCase()}} i folketingssalen</h3>
    <BarChart :data="stats" :xaxis_image="true" chartname="stats_parties" :squash="true"></BarChart>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.chart-holder {
  padding: 0;
  margin: 0;
  width: 95%;
}
@media (max-width: $bp-narrow) {
  .chart-holder {
    width: 100%;
  }
}
</style>

<script>
import BarChart from "@/components/BarChart";
import parties from "@/assets/parties/parties.json";

export default {
  props: ["selectedTags", "dataStats"],
  components: {
    BarChart
  },
  data() {
    return {
      dataStructure: []
    };
  },
  methods: {
    arrayToString: function(arr) {
      let str = "";
      arr.forEach(function(i, index) {
        str += i;
        if (index != arr.length - 1) {
          str += ", ";
        }
      });
      return '"' + str + '"';
    },
    stringToArray(s, sep) {
      return s.split(sep);
    },
    tagsToArray: function() {
      let k = [];
      this.selectedTags.forEach(function(i, index) {
        k.push(i.text);
      });
      return k;
    },
    resetDataStructure: function() {
      // Reset the structure which holds all the parties, so that if a query does not return something, the value is 0
      this.dataStructure = [];
      let self = this;
      Object.keys(parties).forEach(function(key) {
        self.dataStructure.push({ axis: key, count: 0 });
      });
    }
  },
  watch: {
    dataStats: function() {}
  },
  computed: {
    stats() {
      this.resetDataStructure();
      let parsedData = [];
      for (var item of this.dataStats) {
        parsedData.push({
          axis: item["party_system_abbreviation"],
          count: item["count"]
        });
      }

      var key = "axis";
      let match = this.dataStructure.map(el => {
        var found = parsedData.find(s => s[key] === el[key]);
        if (found) {
          el = Object.assign(el, found);
        }
        return el;
      });
      return match;
    },
    keywords() {
      let k = [];
      this.selectedTags.forEach(function(i, index) {
        k.push(i.text);
      });
      return this.arrayToString(k);
    }
  }
};
</script>

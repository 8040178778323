<template>
  <svg :id="chartname" class="bar-chart" :class="xaxis_image ? 'image_axis': '' "></svg>
</template>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import "@/assets/styles/settings.scss";
.bar-chart text {
  fill: #000;
  font: 14px "futura-pt-condensed";
  font-weight: 500;
  text-anchor: middle;
}

.bar-chart .word-bar {
  fill: #d3e8f3 !important;
}

.bar-chart .selected {
  fill: #4162d4 !important;
}

.bar-chart .domain,
.bar-chart .tick line {
  stroke: none !important;
}

.bar-chart.image_axis .axis--x .tick text {
  fill: transparent !important;
}

.bar-chart .y-axis-label {
  font: 18px "futura-pt-condensed";
}

@media (max-width: $bp-narrow) {
  .bar-chart .y-axis-label {
    font: 14px "futura-pt-condensed";
  }
  .bar-chart text {
    font: 12px "futura-pt-condensed";
  }
}
</style>

<script>
import * as d3 from "d3";
export default {
  props: {
    data: {
      default: null
    },
    xaxis_image: {
      default: false
    },
    chartname: {
      default: null
    },
    update_xaxis: {
      default: false
    },
    squash: {
      default: false
    },
    yaxis_start: { default: 0 },
    yaxis_end: { default: 0 },
    selected_party: { default: null },
    yaxis_label: { default: null }
  },
  data() {
    return {
      rendered: false,
      width: this.getWidth(),
      height: this.getHeight(),
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 40
      }
    };
  },
  methods: {
    getWidth() {
      if (window.innerWidth > 1100) {
        return (window.innerWidth / 2) * 0.85 - 60;
      } else {
        // console.log(window.innerWidth);
        return window.innerWidth * 0.85 - 60;
      }
    },
    getHeight() {
      let factor = 1;
      if (this.squash) {
        factor = 0.33;
      }
      if (window.innerWidth > 1100) {
        return ((window.innerWidth / 2) * 0.75 - 60) * factor;
      } else {
        return (window.innerWidth * 0.8 - 60) * factor;
      }
    },

    onResize() {
      // console.log(
      //   this.$el.parentNode.offsetHeight,
      //   this.$el.parentNode.offsetWidth
      // );

      // TODO: On resize, the height becomes bigger and bigger. Rescale to some sort of aspect ratio?
      // https://bl.ocks.org/curran/3a68b0c81991e2e94b19
      //   this.width = this.$parent.$el.offsetWidth;
      //   this.height = this.$parent.$el.offsetHeight;

      this.width = this.getWidth();
      this.height = this.getHeight();

      // console.log("Resized the barchart:", this.width, this.height);
      this.renderChart(this.data);
    },
    initialize: function() {
      this.svg = d3.select("svg#" + this.chartname);
      this.g = this.svg.append("g");

      this.axis_x = this.g.append("g").attr("class", "axis axis--x");

      this.axis_y = this.g.append("g").attr("class", "axis axis--y");
    },
    renderChart: function(renderData) {
      let self = this;
      this.rendered = true;

      this.svg
        .attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom);

      (this.x = d3
        .scaleBand()
        .rangeRound([0, this.width])
        .padding(0.1)),
        (this.y = d3.scaleLinear().rangeRound([this.height, 0]));

      this.g.attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

      this.x.domain(
        renderData.map(function(d) {
          return d.axis;
        })
      );
      this.y.domain([
        this.yaxis_start,
        d3.max(renderData, function(d) {
          return d.count + self.yaxis_end;
        })
      ]);

      this.axis_x
        .attr("transform", "translate(0," + this.height + ")")
        .call(d3.axisBottom(this.x));

      if (this.xaxis_image) {
        this.svg.selectAll(".axis--x .tick .axis-img").remove();
        this.svg.selectAll(".axis--x .tick").each(function(d) {
          var p = d3.select(this);
          p.append("image")
            .attr("class", "axis-img")
            .attr("x", -18)
            .attr("y", 3)
            .attr("width", "5%")
            .attr("height", "5%")
            .attr("xlink:href", self.partyLogo(d));
        });
      }

      this.change(renderData);
    },
    change: function(renderData) {
      let self = this;
      if (!this.rendered) this.renderChart(renderData);

      let ticksize = 8;
      if (
        d3.max(renderData, function(d) {
          return d.count;
        }) < 10
      ) {
        ticksize = d3.max(renderData, function(d) {
          return d.count;
        });
      }

      this.yAxis = d3.axisLeft(self.y).ticks(ticksize);

      if (this.yaxis_label) {
        this.axis_y
          .call(this.yAxis)
          .append("text")
          .attr("class", "y-axis-label")
          .attr("transform", "rotate(-90)")
          .attr("y", -25)
          .attr("x", -20)
          .attr("text-anchor", "middle")
          .text(this.yaxis_label);
      }

      if (this.update_xaxis) {
        this.x.domain(
          renderData.map(function(d) {
            return d.axis;
          })
        );

        this.axis_x
          .call(d3.axisBottom(this.x))
          .selectAll("text")
          .call(this.wrap, this.x.bandwidth())
          .style("text-anchor", "start");
        // .attr("transform", "rotate(-65)");
      }

      this.y.domain([
        this.yaxis_start,
        d3.max(renderData, function(d) {
          return d.count + self.yaxis_end;
        })
      ]);

      this.bars = this.g.selectAll(".word-bar").data(renderData);

      this.bars
        .enter()
        .append("rect")
        .attr("class", function(d) {
          if (self.selected_party != null && d.axis === self.selected_party)
            return "word-bar selected";
          return "word-bar";
        })
        .attr("x", function(d) {
          return self.x(d.axis);
        })
        .attr("width", this.x.bandwidth())
        .attr("y", function(d) {
          return self.y(d.count);
        })
        .attr("height", function(d) {
          return self.height - self.y(d.count);
        });

      var transition = this.svg.transition().duration(500),
        delay = function(d, i) {
          return i;
        };

      transition
        .selectAll(".word-bar")
        .attr("x", function(d) {
          return self.x(d.axis);
        })
        .attr("width", this.x.bandwidth())
        .attr("y", function(d) {
          return self.y(d.count);
        })
        .attr("height", function(d) {
          return self.height - self.y(d.count);
        });

      transition
        .select(".axis--y")
        .call(this.yAxis)
        .selectAll("g");
    },
    wrap: function(text, width) {
      // https://bl.ocks.org/guypursey/f47d8cd11a8ff24854305505dbbd8c07
      text.each(function() {
        var text = d3.select(this),
          words = text
            .text()
            .split(/\s+/)
            .reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", -width / 2)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", -width / 2)
              .attr("y", y)
              .attr("dy", `${++lineNumber * lineHeight + dy}em`)
              .text(word);
          }
        }
      });
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    }
  },
  mounted: function() {
    this.initialize();

    window.addEventListener("resize", this.onResize);
    // this.onResize();
    if (this.data) {
      this.renderChart(this.data);
    } else {
      // console.log("NO DATA");
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    data: function() {
      this.change(this.data);
    },
    selectedPeriod: function() {
      this.change(this.data);
    }
  }
};
</script>

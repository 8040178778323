<template>
  <Section>
    <div class="outer-container">
      <div class="inner-container">
          <div class="text-container">
            <h1>Hvem siger hvad i folketingssalen?</h1>
            <p>Når Folketingets medlemmer mødes og debatterer i folketingssalen, er det oftest i forbindelse med vedtagelse af love eller beslutningsforslag. Størstedelen af regeringens lovforslag fremsættes ved Folketingets åbning den første tirsdag i oktober, hvor regeringen fremlægger lovkataloget, som viser de forskellige lovforslag, som regeringen vil fremsætte i det kommende folketingsår. Åbningsdebatten er da også den længste debat og varer helt op til 14 timer.</p>
            <p>Det er altså ikke kun afstemninger, som tegner et billede af dansk politik. Vi har samlet alle taler fra folketingssalen og analyseret, hvilke ord og begreber der særligt bruges, når forskellige politiske emner debatteres. Og hvordan partierne adskiller sig fra hinanden.</p> 
            <template v-if="view === 'topic'">
              <p>Opdag blandt andet, at Enhedslisten ofte taler om mennesker, børn og frihedsberøvelse, når flygtninge- og asylpolitik debatteres. Dansk Folkeparti taler derimod ofte om grænsebomme, udvisning og kriminalitet.</p>
              <p>Dyk ned i alle talerne, og få en forståelse af, hvad der er i fokus, når {{topicName.toLowerCase()}} debatteres i folketingssalen. </p>
            </template>
            <template v-else>
              <p>Dyk ned i alle talerne, og få en forståelse af, hvad der er i fokus for det valgte parti. </p>
            </template>
          </div>
          <div class="illustration">
              <img :src="illustration" />
          </div>          
      </div>
    </div>  
  </Section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.outer-container {
  height: 100%;
  min-height: calc(100vh - 80px);
  width: 100%;
  background: $color-lightgrey;
  display: flex;
  text-align: left;
  justify-content: center;
}
.inner-container {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 5% 0 5% 0;
  h1 {
    font-family: "futura-pt-bold";
    font-weight: 700;
    text-transform: uppercase;
    font-size: 300%;
    margin: 0;
    color: #000;
    margin-bottom: 20px;
    text-align: left;
  }
  p {
    font-family: "roboto-condensed";
    font-weight: 500;
    font-size: 150%;
    line-height: 1.5em;
    color: #000;
  }
}
.text-container {
  width: 50%;
}
.illustration {
  width: 50%;
  text-align: center;
  img {
    width: 80%;
  }
}
@media (max-width: $bp-narrow) {
  .inner-container {
    width: 100%;
    flex-direction: column;
    padding: 0 5% 0 5%;
  }
  .text-container {
    width: 100%;
  }
  .illustration {
    width: 100%;
  }
}
span.inverse {
  background: #000;
  color: $color-lightgrey;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import Section from "@/components/Section";
export default {
  props: ["topicName", "view"],
  components: {
    Section
  },
  computed: {
    illustration: function() {
      return require("@/assets/icons/intro-speech.svg");
    }
  }
};
</script>

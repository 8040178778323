<template>
        <div v-if="topicName == 'ALT'">
                <p>Alternativet blev dannet i 2013 med et ønske om at ændre måden, der normalt tænkes politik på i Danmark.</p>

                <p>Partiet tager afsæt i seks kerneværdier, der skal kendetegne interne og eksterne arbejdsprocesser såvel som konkrete politiske udspil. De seks kerneværdier er:

                        <ul>
                                <li><b>Mod</b> ‒ mod til at se problemerne i øjnene, men også mod på den fremtid, der er vores.</li>
                                <li><b>Generøsitet</b> ‒ Alt, hvad der kan deles, skal deles med dem, der har lyst.</li>
                                <li><b>Gennemsigtighed</b> ‒ Alle skal have mulighed for at se partiet over skulderen ‒ både på gode og dårlige dage.</li>
                                <li><b>Ydmyghed</b> ‒ over for opgaven, over for dem, vi står på skuldrene af, og over for dem, der kommer efter os.</li>
                                <li><b>Humor</b> ‒ Uden humor, ingen kreativitet. Uden kreativitet, ingen gode ideer. Uden gode ideer, ingen skaberkraft. Uden skaberkraft, intet resultat.</li>
                                <li><b>Empati</b> ‒ At sætte sig i den andens sted og se verden derfra. Og derpå skabe løsninger, hvor alle vinder.</li>
                        </ul>

                        <p>De seks kerneværdier skal ifølge Alternativet være konstante pejlemærker, synlige i det daglige politiske arbejde og i den måde, partiet tænker, taler og handler på ‒ fra debatarrangementer over politiske udspil til kampagneafvikling.</p>

                        <p>Alternativet har været i Folketinget siden 2015.</p>
        </div>
        <div v-else-if="topicName == 'DF'">
                <p>Dansk Folkeparti blev stiftet i 1995 af tidligere medlemmer af Fremskridtspartiet.</p>

                <p>Dansk Folkeparti vil ikke være bundet af hverken liberalisme eller socialisme, men ser det som sin fornemste opgave at værne om de ting, der gør Danmark til et af verdens bedste lande.</p>

                <p><b>Dansk selvstændighed og udbygget folkestyre og monarki</b></p>

                <p>Dansk Folkeparti ønsker at sikre Danmarks selvstændighed, holde fast i det danske folks frihed i eget land og at bevare og udbygge folkestyret og monarkiet.</p>

                <p>Partiet ønsker et land bestående af frie danske borgere, som gives mulighed for at klare sig selv og bestemme over sig selv, men hvor staten samtidig er forpligtet til at støtte og hjælpe de danskere, der på forskellig vis er i vanskeligheder. Frihedsrettighederne skal holdes i hævd og demokratiet beskyttes mod kræfter, der ønsker at omstyrte det.</p>

                <p>Dansk Folkeparti har været i Folketinget siden 1998.</p>
        </div>
        <div v-else-if="topicName == 'EL'">
                <p>I 1989 blev Enhedslisten dannet som en sammenslutning af de tre partier Venstresocialisterne, Danmarks Kommunistiske Parti og Socialistisk Arbejdsparti. Til at begynde med var det et valgteknisk samarbejde, men samarbejdet udviklede sig til en medlemsorganisation, og Enhedslisten er i dag et parti med et politisk program og medlemsdemokrati.</p>

                <p><b>Frihed, lighed, solidaritet og økologisk ansvarlighed</b></p>

                <p>Enhedslisten arbejder for at skabe et socialistisk demokrati i Danmark og internationalt ‒ et samfund, hvor frihed, lighed, solidaritet og økologisk ansvarlighed er i højsædet.</p>

                <p>Denne målsætning betyder ikke, at man skal vente på, at store samfundsforandringer kommer på dagsordenen. Enhedslistens medlemmer og aktive græsrødder støtter og kæmper for enhver lille forbedring, der kan skabe større retfærdighed mellem mennesker og bedre fremtidsmuligheder for kloden. Ved at være ihærdige fortalere for alle små og store fremskridt er de overbevist om, at de bringer de virkelige og afgørende samfundsforandringer nærmere.</p>

                <p>Enhedslisten har været repræsenteret i Folketinget siden 1994.</p>
        </div>
        <div v-else-if="topicName == 'LA'">
                <p>Liberal Alliance er en videreudvikling af partiet Ny Alliance, som blev stiftet af Naser Khader, Anders Samuelsen og Gitte Seeberg den 7. maj 2007. </p>

                <p>I 2008, den 28. august, blev Ny Alliance til Liberal Alliance, og partiet fik et nyt principprogram med et tydeligt liberalt udgangspunkt.</p>

                <p><b>Personlig frihed og lavere skat</b></p>

                <p>Liberal Alliance sætter den personlige frihed i højsædet frem for materiel lighed. Partiet ønsker et samfund præget af frisind med højt til loftet og med plads til mange forskellige holdninger. Det personlige ansvar skal styrkes, og meget af den offentlige drift skal overlades til private.</p>

                <p>Mærkesagerne er:</p>
                <ul>
                        <li>vækst</li>
                        <li>lavere skat og mere for pengene i den offentlige sektor</li>
                        <li>mere personlig frihed og mindre statsindblanding.</li>
                </ul>

                <p>Liberal Alliance har været i Folketinget siden 2007.</p>
        </div>
        <div v-else-if="topicName == 'KF'">
                <p>I 1916 blev Det Konservative Folkeparti dannet som et parti, der skulle varetage den ny middelklasses interesser. Mange af partiets stiftere havde rod i det gamle Højre.</p>

                <p>Formålet var at føre en politik, der talte til konservativt indstillede medborgere inden for alle samfundsgrupper.</p>
                <p><b>Frihed og ansvar som værdigrundlag</b></p>
                <p>Partiets værdigrundlag bygger på troen på frihed og ansvar. Det enkelte menneske skal have frihed til at forme sin egen tilværelse og til at skabe sin egen lykke. Frihed og ansvar er to sider af samme sag ‒ ansvar for at forvalte sin egen frihed uden at krænke andres ret til samme frihed og ansvar for fællesskabet, hvor hver især respekterer de andres egenart og retten til forskellighed.</p>

                <p>Ansvar for fællesskabet betyder, at der skal udvises medmenneskelighed og socialt engagement over for dem, der ikke kan klare sig selv, eller dem, hvis frihed er krænket eller truet. Det gælder ansvaret over for de værdier, der knytter os sammen: </p>

                <ul>
                        <li>vores land</li>
                        <li>vores kongehus</li>
                        <li>kristendommen</li>
                        <li>vores sprog</li>
                        <li>vores demokratiske styreform og grundlovssikrede rettigheder </li>
                        <li>vores fælles historie.</li>
                </ul>
                <!-- <p>Det Konservative Folkeparti ønsker at forbedre det danske samfund med udgangspunkt i en politik, der på en gang er fornyende og bevarende.</p>         -->
        </div>
        <div v-else-if="topicName == 'RV'">
                <p>Den 21. maj 1905 blev Det Radikale Venstre stiftet efter en splittelse af partiet Venstre.</p>

                <p>Hovedpunkterne i partiets første program var:</p>

                <ul>
                        <li>dansk neutralitet</li>
                        <li>nedsættelse af militærudgifterne</li>
                        <li>stemmeret til alle over 21 år. hemmelige valg</li>
                        <li>adgang til folkeafstemninger</li>
                        <li>progressiv personbeskatning</li>
                        <li>arbejdsløshedsforsikring</li>
                        <li>nedsættelse af arbejdstiden</li>
                        <li>bedre omsorg for ældre, børn og svage </li>
                        <li>frihed i undervisning og åndsliv.</li>
                </ul>

                <p>Demokrati og humanisme er i fokus for partiet. Det radikale værdigrundlag består af frisind, social ansvarlighed, respekt for folkestyret, økologisk bevidsthed, internationalt engagement og tillid til en markedsøkonomi i samspil med det offentlige.</p>

                <p>Partiet kalder sig selv et socialliberalt parti og bygger på principperne: folkestyre og demokrati, frihed og fællesskab og bæredygtighed.</p>

                <p>De Radikale har været i Folketinget siden 1905.</p>
        </div>
        <div v-else-if="topicName == 'S'">
                <p>Socialdemokratiet blev dannet i 1871 som et arbejderparti med tilknytning til fagbevægelsen af Louis Pio, Harald Brix og Paul Geleff. Formålet var at organisere den fremvoksende arbejderklasse på et socialistisk grundlag. Retfærdige levevilkår og demokratiske rettigheder skulle sikres for alle. </p>

                <p><b>Frihed, lighed og solidaritet som grundværdier</b></p>

                <p>Partiet arbejdede for frihed, lighed og solidaritet som grundlæggende værdier for arbejdet.</p>

                <p>Frihed betyder, at det enkelte menneskes demokratiske rettigheder sikres. </p>

                <p>Lighed betyder en socialt retfærdig fordeling af samfundets goder. Der skal være lige muligheder og lige adgang for alle, og det uanset køn, etnisk baggrund eller socialklasse. Lighed handler også om at respektere sine medmenneskers ret til at leve et anderledes liv end en selv. </p>

                <p>Solidaritet betyder, at vi er der for hinanden ‒ at vi sikrer samfundets svage som for eksempel de gamle, de syge og de arbejdsløse. Men solidaritet handler også om, at vi hver især bidrager til og tager ansvar for fællesskabet.</p>

                <p>Socialdemokratiet fik valgt medlemmer til Folketinget første gang i 1884 og har været repræsenteret lige siden.</p>
        </div>
        <div v-else-if="topicName == 'SF'">
                <p>I 1959 blev Socialistisk Folkeparti (SF) dannet efter en splittelse i Danmarks Kommunistiske Parti (DKP) om, hvor selvstændig en linje man skulle føre i forhold til Sovjetunionen. Socialistisk Folkeparti ønskede at indføre socialismen med demokratiske midler.</p>

                <p>SF er et socialistisk parti. For SF handler socialisme først og fremmest om at give mennesker større magt over deres eget liv og over samfundsudviklingen gennem udvidelse af demokratiet. </p>

                <p><b>Lighed og social sikkerhed</b></p>

                <p>Det enkelte menneske skal have de bedst mulige betingelser for at leve det liv, det gerne vil. Derfor skal samfundet være præget af en høj grad af lighed og social sikkerhed. SF er et grønt parti, der sætter det fælles ansvar for miljøet højt. Det betyder en grøn omstilling af produktionen og at indtænke miljøhensyn i alle beslutninger.</p>

                <p>SF kom i Folketinget første gang i 1960.</p>
        </div>
        <div v-else-if="topicName == 'V'">
                <p>Efter grundlovsrevisionen i 1866 fandtes der i Folketinget tre Venstregrupper, som i 1870 slog sig sammen og dannede Det Forenede Venstre. Venstres rødder går længere tilbage, men 1870 står som det officielle år for dannelsen af partiet. Efter 1870 tog opbygningen af lokale vælgerforeninger fart, og i 1929 blev Venstres landsorganisation dannet.</p>

                <p><b>Liberalt menneskesyn</b></p>
                <p>Venstres politik bygger på et liberalt menneskesyn, hvor mennesket trives bedst i frihed under ansvar ‒ frihed forstået som fravær af tvang, men også forstået således, at mennesket frit kan tage ansvar for sit eget liv og medansvar for andre mennesker og fællesskabet.</p>

                <p>Frisind er en vigtig del af det liberale tankesæt. Frisind betyder for Venstre, at man anerkender det andet menneskes ret til frit at tænke, tro og tale – og til frit at vælge livsform. Ifølge Venstre, betyder frisind ikke, at man skal acceptere alle livsværdier og livsmønstre som lige gode. Frisind er ikke det samme som holdningsløshed. Mennesket har ret til at leve sit liv i frihed og gå sine egne veje ‒ alene eller sammen med andre ‒ forudsat at det ikke krænker andre menneskers tilsvarende frihed.</p>

                <p>Venstre lægger vægt på rettigheder som disse:</p>

                <ul>
                        <li>den personlige frihed</li>
                        <li>privatlivets ukrænkelighed</li>
                        <li>den private ejendomsret</li>
                        <li>ytringsfriheden</li>
                        <li>trosfriheden</li>
                        <li>foreningsfriheden</li>
                        <li>forsamlingsfriheden</li>
                        <li>erhvervsfriheden.</li>
                </ul>

                <!-- <p>Frihedsrettigheder skal gælde for alle uanset køn og politisk, religiøs, etnisk og seksuel baggrund</p>         -->
        </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

p {
  font-family: "roboto-condensed";
  font-weight: 500;
  font-size: 120%;
  color: #000;
  text-align: justify;
}

@media (max-width: $bp-narrow) {
  p {
    font-size: 150%;
  }
}
ol,
ul,
li {
  text-align: left;
}
</style>

<script>
export default {
  props: ["topicName"],
  data() {
    return {};
  }
};
</script>

<template>
  <div class="chart-holder">
    <h3>Hvem bruger ordet mest:</h3>
    <BarChart :data="stats" :xaxis_image="false" chartname="stats_politicians" :update_xaxis="true" :squash="true"></BarChart>
    <!-- <li v-if="dataStats" v-for="(item, index) in dataStats" class="speaker-list">
            <span @click="selectPolitician(item.person_id, item.first_name + item.last_name, item.party_system_abbreviation)">{{item.first_name}} {{item.last_name}}</span> 
            ({{item.party_system_abbreviation}}): {{item.count}}
        </li>

        <PoliticianInfo :speaker="selectedSpeaker" :showPoliticianInfo="showPoliticianInfo" @closePolitician="showPoliticianInfo = false" /> -->
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.chart-holder {
  padding: 0;
  margin: 0;
  width: 95%;
}
@media (max-width: $bp-narrow) {
  .chart-holder {
    width: 98%;
  }
}
.speaker-list {
  list-style: none;
  text-align: left;
  cursor: pointer;
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
import PoliticianInfo from "@/components/PoliticianInfo";
import BarChart from "@/components/BarChart";

export default {
  props: ["selectedTags", "dataStats"],
  components: {
    PoliticianInfo,
    BarChart
  },
  data() {
    return {
      visibleCard: null,
      parties,
      selectedSpeaker: null,
      selectedParty: null,
      showPoliticianInfo: false,
      dataStructure: []
    };
  },
  methods: {
    selectPolitician: function(id, name, party) {
      // console.log("Clicked: ", id, name);
      this.selectedSpeaker = {
        partyID: party,
        politician: name,
        politicianID: id,
        politicianPhoto: ""
      };
      this.showPoliticianInfo = true;
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    },
    showInfoCard: function(index) {
      this.visibleCard = index;
    },
    hideInfoCard: function() {
      this.visibleCard = null;
    },
    infoCardClass: function(index) {
      if (this.visibleCard === index) {
        return "show-card";
      } else {
        return "";
      }
    },
    hideSpeakerClass: function(index) {
      if (this.visibleCard && this.visibleCard !== index) {
        return "hide-speaker";
      } else {
        return "";
      }
    },
    resetDataStructure: function() {
      // Reset the structure which holds all the parties, so that if a query does not return something, the value is 0
      this.dataStructure = [];
      let self = this;
      Object.keys(parties).forEach(function(key) {
        self.dataStructure.push({ axis: key, count: 0 });
      });
    }
  },
  watch: {
    dataStats: function() {
      //console.log(this.dataStats);
    }
  },
  computed: {
    stats() {
      this.resetDataStructure();
      let parsedData = [];
      for (var item of this.dataStats) {
        parsedData.push({
          axis: item.first_name + " " + item.last_name,
          count: item.count
        });
      }

      // var key = "axis";
      // let match = this.dataStructure.map(el => {
      //   var found = parsedData.find(s => s[key] === el[key]);
      //   if (found) {
      //       el = Object.assign(el, found);
      //   }
      //   return el;
      // });
      // console.log(match[0].axis);
      return parsedData;
    }
  }
};
</script>

<template>
  <svg id="horizontal-bar-chart-stats" class="horizontal-bar-chart"></svg>
</template>

<style lang="scss" scoped>
</style>

<style lang="scss">
.horizontal-bar-chart text {
  fill: #000;
  font: 14px "futura-pt-condensed";
  font-weight: 500;
  text-anchor: end;
}
.horizontal-bar-chart text.amount {
  fill: #fff;
  font: 12px "futura-pt-condensed";
  text-anchor: end;
}
path.domain,
g.tick line {
  visibility: hidden;
}
.mean {
  stroke-width: 1px;
  stroke: #f6874f;
  // stroke-dasharray : 2;
}
.new-text {
  fill: #f6874f !important;
  text-anchor: start !important;
}
</style>

<script>
import * as d3 from "d3";
import settings from "@/assets/styles/settings.scss";
export default {
  props: ["data", "selectedPeriod", "topicName", "type"],
  data() {
    return {
      settings,
      rendered: false,
      data_attribute: "nLaws",
      // width: 600, //this.$parent.$el.clientWidth - 50,
      // height: 500, //this.$parent.$el.clientHeight - 50,
      // width: this.$parent.$el.clientWidth - 220,
      // height: this.$parent.$el.clientHeight - 50,
      width: this.getWidth(),
      height: this.getHeight(),
      margin: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 170
      }
    };
  },
  methods: {
    onResize() {
      // TODO: On resize, the height becomes bigger and bigger. Rescale to some sort of aspect ratio?
      // https://bl.ocks.org/curran/3a68b0c81991e2e94b19
      this.width = this.getWidth();
      this.height = this.getHeight();

      // console.log(this.width, this.height)
      document.getElementById("horizontal-bar-chart-stats").innerHTML = "";
      this.renderChart(this.loadData());
    },
    getWidth() {
      if (window.innerWidth > 1100) {
        return (window.innerWidth / 2) * 0.8 - 190;
      } else {
        // console.log(window.innerWidth)
        return window.innerWidth * 0.9 - 190;
      }
    },
    getHeight() {
      if (window.innerWidth > 1100) {
        return (window.innerWidth / 2) * 0.8 - 40;
      } else {
        return window.innerWidth * 0.9 - 40;
      }
    },
    getParentBBox() {
      // console.log([
      //   this.$parent.$el.clientWidth,
      //   this.$parent.$el.clientHeight
      // ]);
      return [this.$parent.$el.clientWidth, this.$parent.$el.clientHeight];
    },
    loadData: function() {
      let self = this;

      // Sort topic data decending
      let renderData = JSON.parse(JSON.stringify(this.data));
      renderData = renderData[this.selectedPeriod].sort(function(a, b) {
        return d3.descending(a[self.data_attribute], b[self.data_attribute]);
      });
      return renderData;
    },
    renderChart: function(renderData) {
      let self = this;
      this.rendered = true;
      this.widthScale = d3.scaleLinear().range([0, this.width]);
      this.heightScale = d3
        .scaleBand()
        .rangeRound([0, this.height])
        .paddingInner(0.2);

      this.svg = d3
        .select(".horizontal-bar-chart")
        .attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom)
        .append("g")
        .attr(
          "transform",
          "translate(" + this.margin.left + "," + this.margin.top + ")"
        );

      this.widthScale.domain([
        0,
        d3.max(renderData, function(d) {
          return d[self.data_attribute] + 10;
        })
      ]);
      this.heightScale.domain(
        renderData.map(function(d) {
          return d.topicName;
        })
      );

      this.yAxis = d3.axisLeft(this.heightScale).tickSizeOuter(0);

      this.svg
        .append("g")
        .attr("class", "y-axis")
        .call(this.yAxis)
        .style("text-anchor", "end");

      var sum = d3.sum(renderData, function(d) {
        return d[self.data_attribute];
      });
      var average = sum / renderData.length;

      this.new_text = this.svg
        .append("text")
        .attr("class", "new-text")
        .attr(
          "transform",
          "translate(" + self.widthScale(average) + "," + self.height + ")"
        );

      var line = d3
        .line()
        .x(function(d, i) {
          return self.widthScale(average);
        })
        .y(function(d, i) {
          return self.heightScale(d.topicName);
        });

      // Average:
      // this.avg = this.svg
      //   .append("path")
      //   .datum(renderData)
      //   .attr("class", "mean")
      //   .attr("d", line);

      this.change(renderData);
    },
    change: function(renderData) {
      let self = this;
      if (!this.rendered) this.renderChart(renderData);

      // Copy-on-write since tweens are evaluated after a delay.
      var y0 = this.heightScale
        .domain(
          renderData.map(function(d) {
            return d.topicName;
          })
        )
        .copy();
      //var x0 = this.widthScale.domain( [0, d3.max(renderData, function(d) {return d.nLaws + 10;})] ).copy();

      this.bars = this.svg.selectAll("rect").data(renderData);
      this.amount = this.svg.selectAll(".amount").data(renderData);

      this.bars
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", 0)
        .attr("y", function(d) {
          return self.heightScale(d.topicName);
        })
        .attr("width", function(d) {
          return self.widthScale(+d[self.data_attribute]);
        })
        .attr("height", self.heightScale.bandwidth())
        .style("fill", function(d, i) {
          if (d.topicName === self.topicName) {
            return "#f6874f";
          } else {
            return "#4162d4";
          }
        });

      this.amount
        .enter()
        .append("text")
        .attr("class", "amount")
        .attr("x", function(d) {
          return self.widthScale(d[self.data_attribute]) - 5;
        })
        .attr("y", function(d) {
          return (
            self.heightScale(d.topicName) + self.heightScale.bandwidth() / 2
          );
        })
        .attr("dy", ".35em");

      var sum = d3.sum(renderData, function(d) {
        return d[self.data_attribute];
      });
      var average = sum / renderData.length;

      var line = d3
        .line()
        .x(function(d, i) {
          return self.widthScale(average);
        })
        .y(function(d, i) {
          return self.heightScale(d.topicName);
        });

      var transition = this.svg.transition().duration(500),
        delay = function(d, i) {
          return i;
        };

      transition
        .selectAll(".bar")
        .attr("y", function(d) {
          return y0(d.topicName);
        })
        .attr("width", function(d) {
          return self.widthScale(+d[self.data_attribute]);
        })
        .style("fill", function(d, i) {
          if (d.topicName === self.topicName) {
            return "#f6874f";
          } else {
            return "#4162d4";
          }
        });

      transition
        .selectAll(".amount")
        .attr("y", function(d) {
          return y0(d.topicName) + self.heightScale.bandwidth() / 2;
        })
        .attr("x", function(d) {
          return self.widthScale(d[self.data_attribute]) - 3;
        })
        .text(function(d) {
          return d[self.data_attribute].toFixed(0);
        });

      // Average:
      // transition
      //   .selectAll(".mean")
      //   .attr("d", line);
      // d3.select(".mean").raise();

      d3.selectAll(".amount").raise();

      // Average:
      // transition.selectAll(".mean-text")
      //   //.attr("transform", "translate(" + ( self.widthScale(average) ) + "," + (self.height - 10) + ")")
      //   .delay(delay)

      transition
        .select(".y-axis")
        .call(this.yAxis)
        .selectAll("g");

      // Average:
      // transition
      //   .select(".new-text")
      //   .attr(
      //     "transform",
      //     "translate(" + self.widthScale(average) + "," + self.height + ")"
      //   )
      //   // .delay(delay)
      //   .text("Gennemsnit for perioden: " + average.toFixed(0));
    },
    update: function(renderData) {
      if (!this.rendered) this.renderChart(renderData);
      let self = this;
      var bars = this.svg.selectAll("rect").data(renderData);

      bars
        .transition()
        .duration(1000)
        .attr("y", function(d) {
          return self.heightScale(d.topicName);
        })
        .attr("height", self.heightScale.bandwidth());

      bars
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", 0)
        .attr("y", function(d) {
          return self.heightScale(d.topicName);
        })
        .attr("width", function(d) {
          return self.widthScale(+d[self.data_attribute]);
        })
        .attr("height", self.heightScale.bandwidth())
        .style("fill", function(d, i) {
          if (d.topicName == self.topicName) {
            return "#f6874f";
          } else {
            return "#4162d4";
          }
        });

      var transition = this.svg.transition().duration(750),
        delay = function(d, i) {
          return i * 50;
        };

      transition
        .select(".y-axis")
        .call(this.yAxis)
        .selectAll("g")
        .delay(delay);
    }
  },
  mounted: function() {
    window.addEventListener("resize", this.onResize);
    // this.onResize();

    if (this.type === "party") {
      this.data_attribute = "topicMinutes";
    } else if (this.type === "topic") {
      this.data_attribute = "nLaws";
    }
    if (this.data) {
      this.renderChart(this.loadData());
    } else {
      // console.log("NO DATA");
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    data: function() {
      // console.log("DATA ARRIVED");
      this.change(this.loadData());
    },
    selectedPeriod: function() {
      this.change(this.loadData());
    }
  }
};
</script>
<template>
  <div class="overlay-container" :style="!showPartyInfo?'':'opacity: 1; z-index: 100'">

    <div class="outer-container">
      <div class="inner-container">
        <div class="close-btn" @click="$emit('closePartyInfo')">
          <img src="@/assets/icons/cancel.svg" alt="">
        </div>
        <div class="logo-container">
          <img v-if="party" :src="partyLogo(party)" alt="">
        </div>
        <div class="title-container">
          <div class="party">
            <p v-if="party">{{parties[party].name.toUpperCase()}}</p>
          </div>
        </div>
        <div class="info-container">
          <!-- <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </p> -->
        </div>
      </div>

    </div>
  </div>
  
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.5s;
  z-index: -10;
}
.outer-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: $color-lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 6%;
  height: 6%;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
  }
}
.inner-container {
  height: 90%;
  width: 90%;
  position: relative;
  padding: 10%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.logo-container {
  width: 35%;
  height: 35%;
  img {
    width: 100%;
    height: 100%;
    // filter: saturate(0.6);
  }
}
.title-container {
  width: 58%;
  height: 35%;
  text-align: left;
  .party {
    height: 20%;
    width: 100%;
    font-size: 150%;
    p {
      font-family: "futura-pt";
      font-weight: 700;
      width: 100%;
      margin: 0;
    }
  }
}
.info-container {
  width: 100%;
  height: 60%;
  p {
    text-align: justify;
  }
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
export default {
  props: ["party", "showPartyInfo"],
  data() {
    return {
      parties
    };
  },
  methods: {
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_med.png`);
    }
  }
};
</script>

<template>
  <svg :id="chartname" class="line-chart" :class="xaxis_image ? 'image_axis': '' "></svg>
</template>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import "@/assets/styles/settings.scss";
.line-chart text {
  fill: #000;
  font: 14px "futura-pt-condensed";
  font-weight: 500;
  text-anchor: end;
}
.line-chart .axis {
  fill: red;
}
.line-chart .chart-line {
  fill: none;
  stroke: #f6cd5f;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: 5;
}

.line-chart.image_axis .axis--x .tick text {
  fill: transparent !important;
}

.line-chart .chart-circle {
  fill: #f6cd5f;
}

@media (max-width: $bp-narrow) {
  .line-chart text {
    font: 12px "futura-pt-condensed";
  }
}
</style>

<script>
import * as d3 from "d3";
export default {
  props: ["data", "xaxis_image", "chartname"],
  data() {
    return {
      rendered: false,
      width: this.getWidth(),
      height: this.getHeight(),
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 40
      }
    };
  },
  methods: {
    getWidth() {
      if (window.innerWidth > 1100) {
        return (window.innerWidth / 2) * 0.85 - 60;
      } else {
        // console.log(window.innerWidth);
        return window.innerWidth * 0.85 - 60;
      }
    },
    getHeight() {
      let factor = 0.33;
      if (window.innerWidth > 1100) {
        return ((window.innerWidth / 2) * 0.8 - 60) * factor;
      } else {
        return (window.innerWidth * 0.8 - 60) * factor;
      }
    },
    onResize() {
      // TODO: On resize, the height becomes bigger and bigger. Rescale to some sort of aspect ratio?
      // https://bl.ocks.org/curran/3a68b0c81991e2e94b19
      this.width = this.getWidth();
      this.height = this.getHeight();

      // console.log(this.width, this.height); // H
      this.renderChart(this.data);
    },
    initialize: function() {
      this.svg = d3.select("svg#" + this.chartname);
      this.g = this.svg.append("g");

      this.axis_x = this.g.append("g").attr("class", "axis axis--x");

      this.axis_y = this.g.append("g").attr("class", "axis axis--y");

      this.line = this.g.append("path").attr("class", "chart-line");
    },
    renderChart: function(renderData) {
      let self = this;
      this.rendered = true;

      this.svg
        .attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom);

      this.x = d3.scalePoint().range([20, this.width - this.margin.right]);
      this.y = d3.scaleLinear().rangeRound([this.height, 0]);

      this.g.attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

      this.x.domain(
        renderData.map(function(d) {
          return d.axis;
        })
      );
      this.y.domain([
        0,
        d3.max(renderData, function(d) {
          return d.count;
        })
      ]);

      this.axis_x
        .attr("transform", "translate(0," + (this.height + 20) + ")")
        .call(d3.axisBottom(this.x))
        .selectAll("text")
        .style("text-anchor", "middle");

      if (this.xaxis_image) {
        this.svg.selectAll(".axis--x .tick .axis-img").remove();
        this.svg.selectAll(".axis--x .tick").each(function(d) {
          var p = d3.select(this);
          p.append("image")
            .attr("class", "axis-img")
            .attr("x", -15)
            .attr("y", 3)
            .attr("width", "30px")
            .attr("height", "30px")
            .attr("xlink:href", self.partyLogo(d));
        });
      }

      // this.axis_y
      //     .call(this.yAxis)
      //     .append("text")
      //     .attr("transform", "rotate(-90)")
      //     .attr("y", 6)
      //     .attr("dy", "0.71em")
      //     .attr("text-anchor", "end")
      //     // .text("Frequency");

      // Different curves: http://bl.ocks.org/d3indepth/b6d4845973089bc1012dec1674d3aff8
      // http://bl.ocks.org/emmasaunders/c25a147970def2b02d8c7c2719dc7502
      this.valueline = d3
        .line()
        .curve(d3.curveCardinal)
        .x(function(d) {
          return self.x(d.axis);
        })
        .y(function(d) {
          return self.y(d.count);
        });

      this.line.datum(renderData);

      this.change(renderData);
    },
    change: function(renderData) {
      let self = this;
      if (!this.rendered) this.renderChart(renderData);

      let ticksize = 8;
      if (
        d3.max(renderData, function(d) {
          return d.count;
        }) < 10
      ) {
        ticksize = d3.max(renderData, function(d) {
          return d.count;
        });
      }
      this.yAxis = d3.axisLeft(self.y).ticks(ticksize);

      this.y.domain([
        0,
        d3.max(renderData, function(d) {
          return d.count;
        })
      ]);

      this.circles = this.g.selectAll("circle").data(renderData);

      this.circles.exit().remove();

      var transition = this.svg.transition().duration(500),
        delay = function(d, i) {
          return i;
        };

      transition.selectAll(".chart-line").attr("d", this.valueline(renderData));

      this.circles
        .transition(transition)
        .attr("r", 5.0)
        .attr("cx", function(d) {
          return self.x(d.axis);
        })
        .attr("cy", function(d) {
          return self.y(d.count);
        });

      this.circles
        .enter()
        .append("circle")
        .attr("r", 5.0)
        .attr("cx", function(d) {
          return self.x(d.axis);
        })
        .attr("cy", function(d) {
          return self.y(d.count);
        })
        .attr("class", "chart-circle");

      transition
        .select(".axis--y")
        .call(this.yAxis)
        .selectAll("g");
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    }
  },
  mounted: function() {
    this.initialize();

    window.addEventListener("resize", this.onResize);
    this.onResize();
    if (this.data) {
      this.renderChart(this.data);
    } else {
      // console.log("NO DATA");
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    data: function() {
      this.change(this.data);
    },
    selectedPeriod: function() {
      this.change(this.data);
    }
  }
};
</script>

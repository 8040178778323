<template>
  <div class="outer-container">
    <div class="inner-container" v-if="selectedTags.length > 0 || userSearch === true">

      <template v-if="view === 'topic'">
        <StatsTotal :selectedTags="selectedTags" v-if="queryResult.total" :dataStats="queryResult.total.response" :view="view" />
        <StatsParties :selectedTags="selectedTags" v-if="queryResult.parties" :dataStats="queryResult.parties.response" />
        <StatsSpeakers :selectedTags="selectedTags" v-if="queryResult.speakers" :dataStats="queryResult.speakers.response" />
      </template>
      <template v-else>
        <!-- Party results
        <StatsTotal :selectedTags="selectedTags" :dataStats="queryResult.party_total.response" :view="view" :partyID="partyID" /> -->
        <Speeches v-if="showSpeeches" :loadSpeeches="loadSpeeches" :selectedTags="selectedTags" :view="view" :partyID="partyID" />
      </template>

      <div class="speeches-holder" v-if="view === 'topic'">
        <div class="toggle">
          <button v-if="!showSpeeches" v-on:click="fetchSpeeches">Vis talerne</button>
          <button v-if="showSpeeches" v-on:click="fetchSpeeches">Skjul talerne</button>
        </div>
        <Speeches v-if="showSpeeches" :loadSpeeches="loadSpeeches" :selectedTags="selectedTags" :view="view" :partyID="partyID" />
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.outer-container {
  height: 100%;
  width: 100%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-container {
  height: 100%;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .speeches-holder {
    margin-bottom: 50px;
    .toggle {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}
@media (max-width: $bp-narrow) {
  .inner-container {
    padding: 2px;
  }
}
button {
  background-color: #3c5fd7;
  color: #fff;
  font-family: "futura-pt-condensed";
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
  border-radius: 2px !important;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
</style>

<script>
import globalConfig from "@/assets/globalConfig.json";
import axios from "axios";
import StatsTotal from "./StatsTotal";
import StatsParties from "./StatsParties";
import StatsSpeakers from "./StatsSpeakers";
import Speeches from "./Speeches";

import parties from "@/assets/parties/parties.json";
import PoliticianInfo from "@/components/PoliticianInfo";
import PartyInfo from "@/components/PartyInfo";

export default {
  props: ["selectedTags", "userSearch", "view", "partyID"],
  components: {
    StatsTotal,
    StatsParties,
    StatsSpeakers,
    Speeches,
    PoliticianInfo,
    PartyInfo
  },
  data() {
    return {
      globalConfig,
      parties,
      visibleCard: null,
      selectedSpeaker: null,
      selectedParty: null,
      showPoliticianInfo: false,
      showPartyInfo: false,
      query: null,
      queryResult: {
        total: null,
        parties: null,
        speakers: null,
        party_total: null
      },
      values: [
        { name: "Page A", pv: 2000 },
        { name: "Page B", pv: 3000 },
        { name: "Page C", pv: 1200 }
      ],
      api_calls: ["/stats/", "/stats/parties/", "/stats/speakers/"],
      res: null,
      loadSpeeches: false,
      showSpeeches: false,
      partyTotals: null,
      start: '2011-01-01',
      end: '2018-12-31'
    };
  },
  methods: {
    selectPolitician: function(id, name, party) {
      this.selectedSpeaker = {
        partyID: party,
        politician: name,
        politicianID: id,
        politicianPhoto: ""
      };
      this.showPoliticianInfo = true;
    },
    selectParty: function(party) {
      this.selectedParty = party;
      this.showPartyInfo = true;
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    },
    showInfoCard: function(index) {
      this.visibleCard = index;
    },
    hideInfoCard: function() {
      this.visibleCard = null;
    },
    infoCardClass: function(index) {
      if (this.visibleCard === index) {
        return "show-card";
      } else {
        return "";
      }
    },
    hideSpeakerClass: function(index) {
      if (this.visibleCard && this.visibleCard !== index) {
        return "hide-speaker";
      } else {
        return "";
      }
    },
    arrayToString: function(arr) {
      let str = "";
      arr.forEach(function(i, index) {
        str += i.text;
        if (index != arr.length - 1) {
          str += " ";
        }
      });
      return str;
    },
    stringToArray(s, sep) {
      return s.split(sep);
    },
    getData: function(url) {
      return axios.get(url);
    },
    fetchSpeeches: function() {
      this.showSpeeches = !this.showSpeeches;
    }
  },
  watch: {
    selectedTags: function() {
      // TODO: Create a chained flow of three different API calls when this fires.
      let self = this;
      let base_url;
      if (this.selectedTags.length >= 1) {
        if (this.selectedTags.length > 1) {
          this.query = self.arrayToString(this.selectedTags);
        } else {
          this.query = this.selectedTags[0].text;
        }
        this.query += '/' + this.start + '/' + this.end;
        if (this.view === "topic") {
          base_url = globalConfig.apiBaseUrl + "/search/match";
          axios
            .get(base_url + self.api_calls[0] + self.query)
            .then(response => (self.queryResult["total"] = response.data))
            .then(function() {
              axios
                .get(base_url + self.api_calls[1] + self.query)
                .then(response => (self.queryResult["parties"] = response.data))
                .then(function() {
                  axios
                    .get(base_url + self.api_calls[2] + self.query)
                    .then(
                      response => (self.queryResult["speakers"] = response.data)
                    );
                });
            });
        } else if (this.view === "party") {
          base_url = globalConfig.apiBaseUrl + "/party";
          axios
            .get(base_url + "/match/stats/" + self.query)
            .then(
              response => (self.queryResult["party_total"] = response.data)
            );

          // axios
          //   .get(base_url + "/match/stats/" + self.query)
          //   .then(function(response) {
          //     self.queryResult['party_total'] = JSON.stringify(response.data.response);
          //   });
        }
      }
    }
  },
  mounted() {
    if (this.view === "party") {
      this.showSpeeches = true;
      // Get the total amount of speeches which each party has held per year.
      let self = this;
      let base_url = globalConfig.apiBaseUrl + "/party";

      axios
        .get(base_url + "/total")
        .then(response => (self.partyTotals = response.data.response));
    }
  }
};
</script>

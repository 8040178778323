<template>
  <div class="chart-holder">
    <h3>Så meget nævnes {{keywords.toLowerCase()}} i folketingssalen</h3>
    <template v-if="view === 'topic'">
      <LineChart :data="stats" :xaxis_image="false" chartname="stats_total"></LineChart>
      <!-- <p>Bemærk: Deler af talerne fra 2011 og 2012 mangler, og kan derfor ikke sammenlignes med de øvrige år.</p> -->
    </template>
    <template v-else>
      <!-- <LineChartParty :data="statsParty" chartname="stats_total" :partyID="partyID"></LineChartParty> -->
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.chart-holder {
  padding: 0;
  margin: 0;
  width: 95%;
}
p {
  font-size: 70%;
}
@media (max-width: $bp-narrow) {
  .chart-holder {
    width: 98%;
  }
  p {
    font-size: 90%;
  }
}
</style>

<script>
import BarChart from "@/components/BarChart";
import LineChart from "@/components/LineChart";
import LineChartParty from "@/components/LineChartParty";

export default {
  props: ["selectedTags", "dataStats", "view", "partyID"],
  components: {
    BarChart,
    LineChart,
    LineChartParty
  },
  data() {
    return {
      s: null,
      begin: 2011,
      start: 2011,
      end: 2018,
      dataStructure: []
    };
  },
  methods: {
    arrayToString: function(arr) {
      let str = "";
      arr.forEach(function(i, index) {
        str += i;
        if (index != arr.length - 1) {
          str += ", ";
        }
      });
      return '"' + str + '"';
    },
    resetDataStructure: function() {
      // Reset the structure which holds all the parties, so that if a query does not return something, the value is 0
      this.dataStructure = [];
      let self = this;
      while (this.start <= this.end) {
        this.dataStructure.push({ axis: this.start, count: 0 });
        this.start++;
      }
      this.start = this.begin;
    }
  },
  watch: {
    dataStats: function() {}
    // selectedTags: function() {}
  },
  computed: {
    stats() {
      this.resetDataStructure();
      let parsedData = [];
      for (var item of this.dataStats) {
        parsedData.push({ axis: item.axis, count: item.count });
      }

      var key = "axis";
      let match = this.dataStructure.map(el => {
        var found = parsedData.find(s => s[key] === el[key]);
        if (found) {
          el = Object.assign(el, found);
        }
        return el;
      });
      return match;
    },
    statsParty() {
      return this.dataStats;
    },
    keywords() {
      let k = [];
      this.selectedTags.forEach(function(i, index) {
        k.push(i.text);
      });
      return this.arrayToString(k);
    }
  }
};
</script>

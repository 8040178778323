<template>
  <div class="outer-container bg-lightgreen" id="word-search-container" :class="stickyWordSearchContainer?'sticky':''">
    <div class="inner-container">
      <h1 class="title title">Søg i talerne</h1>
      <p>Søg efter ord (minimum 3 bogstaver) i alle taler fra folketingssalen siden 2011.</p>

      <vue-tags-input class="custom-tag-input" :placeholder="placeholder" v-model="tag" :tags="tags" :validation="validation" @tags-changed="newTags => tags = newTags" />

      <!-- <div>
        <input type="radio" value="" id="search-structure-1" v-model="searchStructure">
        <label for="search-structure-1">Sammenlign</label>

        <input type="radio" value="" id="search-structure-2" v-model="searchStructure">
        <label for="search-structure-2">Både og</label>        

        <input type="radio" value="" id="search-structure-3" v-model="searchStructure">
        <label for="search-structure-3">Enten eller</label>                
      </div> -->

      <div>
        <input type="checkbox" id="search-setting" v-model="searchSetting" unchecked>
        <label for="search-setting">Præcis søgning</label>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.sticky {
  position: fixed;
  top: 100px;
  left: 0;
  width: 50% !important;
}

.bg-lightgreen {
  background: $color-lightgreen;
}

.bg-lightblue {
  background: $color-lightblue;
}

.bg-red {
  background: $color-red;
}

.bg-yellow {
  background: $color-yellow;
}

.bg-white {
  background: white;
}

.bg-orange {
  background: $color-orange;
}

.bg-turquoise {
  background: $color-turquoise;
}

.white-text {
  color: white;
}

.outer-container {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  text-align: left;
  transition: 0.3s;
}
@media (max-width: $bp-narrow) {
  .outer-container {
    height: calc(100vw);
  }
}
.inner-container {
  margin-left: 10%;
  width: 85%;
  position: relative;
}

.quotes {
  position: absolute;
  top: -20%;
  left: 0;
  font-size: 900%;
  font-family: "Times New Roman", Times, serif;
}

.title {
  font-size: 250%;
  margin: 0;
}

p {
  color: rgba(0, 0, 0, 0.4);
  font-family: "roboto";
  font-weight: 500;
  margin-bottom: 5%;
}

.subtitle,
.topic {
  font-size: 150%;
}
.down-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  p {
    margin: 0;
  }
  img {
    width: 35px;
  }
}
</style>

<style lang="scss">
@import "@/assets/styles/settings.scss";

// Had to do this unscoped, as the styles wouldn't overwrite if it was scoped.
.custom-tag-input {
  margin-left: -10px;
  .input {
    background-color: #d9f4e3 !important;
    border: 0px !important;
  }
  input {
    background-color: #d9f4e3 !important;
    font-family: "futura-pt-condensed";
    font-weight: 700;
    font-size: 200% !important;
    color: #000 !important;
    text-transform: uppercase;
    border-bottom: 3px solid #fff !important;
  }
  .icon-close {
    font-size: 50% !important;
  }
  // input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  //   color: #000;
  //   opacity: 1; /* Firefox */
  // }
  // input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  //     color: #000;
  // }
  // input::-ms-input-placeholder { /* Microsoft Edge */
  //     color: #000;
  // }
}

.vue-tags-input {
  max-width: 90% !important;
  width: 90% !important;
}

.tag {
  background-color: #3c5fd7 !important;
  color: #fff !important;
  font-family: "futura-pt-condensed";
  font-weight: 700;
  font-size: 200% !important;
  text-transform: uppercase;
  padding-left: 10px !important;
}

@media (max-width: $bp-narrow) {
  input,
  .vue-tags-input {
    font-size: 100%;
  }
}
</style>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import axios from "axios";
export default {
  props: ["topicName", "view", "searchwords", "partyID"],
  components: {
    VueTagsInput
  },
  data() {
    return {
      userSearch: false,
      randomSearches: [],
      gotData: false,
      selectedPeriod: "allPeriods",
      placeholder: "tilføj søgeord",
      topWords: null,
      selectedTopWords: null,
      updatePlaceholderCheck: false,
      tag: "",
      tags: [],
      windowWidth: window.innerWidth,
      validation: [
        {
          type: "min-length",
          rule: /^.{3,}$/,
          disableAdd: true
        },
        // {
        //   type: "no-numbers",
        //   rule: /^([^0-9]*)$/,
        //   disableAdd: true
        // },
        {
          type: "avoid-item",
          rule: /^(?!Cannot).*$/,
          disableAdd: true
        },
        {
          type: "no-braces",
          rule: text => text.indexOf("{") !== -1 || text.indexOf("}") !== -1,
          disableAdd: true
        }
      ],
      searchSetting: false,
      searchStructure: [],
      stickyWordSearchContainer: false,
      wordSearchContainerPosition: null,
      scrollPosition: 0,
      timer: null
    };
  },
  methods: {
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    },
    handleScroll: function() {
      this.scrollPosition = window.scrollY;
    },
    setPositions: function() {
      let element = document.querySelector("#word-search-container");
      this.windowWidth = window.innerWidth;
      let getElemDistance = function(element) {
        var location = 0;
        if (element.offsetParent) {
          do {
            location += element.offsetTop;
            element = element.offsetParent;
          } while (element);
        }
        location -= 80;
        return location >= 0 ? location : 0;
      };
      this.wordSearchContainerPosition = getElemDistance(element);
    },
    updatePlaceholder: function() {
      if (!this.updatePlaceholderCheck && !this.userSearch) {
        if (this.view === "topic") {
          var item = this.selectedTopWords[
            Math.floor(Math.random() * this.selectedTopWords.length)
          ];
          if (item.length > 1) {
            // this.placeholder = item;
            if (!this.randomSearches.includes(item)) {
              this.tags = [{ text: item }];
              this.randomSearches.push(item);
            }
          }
        } else {
          // Party view:
          this.tags = [
            {
              text:
                this.selectedTopWords[0].length > 2
                  ? this.selectedTopWords[0]
                  : this.selectedTopWords[1]
            }
          ];
        }
      }
    },
    // stopPlaceholderChange: function(e) {
    //   if(!this.updatePlaceholderCheck) {
    //     this.updatePlaceholderCheck = true;
    //     // this.placeholder = "";
    //     this.userSearch = true;
    //     //this.tags=[];
    //   } else if(this.updatePlaceholderCheck && this.placeholder == "" && this.tags.length > 0) {
    //     this.updatePlaceholderCheck = false;
    //     this.userSearch = false;
    //   }
    // },
    updateWords: function() {
      let self = this;
      let words = [];
      if (this.view === "topic") {
        this.searchwords[this.selectedPeriod].forEach(function(i, index) {
          if (i.topicName == self.topicName) {
            words = i.Words;
          }
          return words;
        });
        this.selectedTopWords = words.filter(function(a) {
          return a.length > 2;
        });
      } else if (this.view === "party") {
        this.selectedTopWords = this.searchwords[this.selectedPeriod]
          .filter(x => x.topicID === 99)[0]
          .topWords.slice(0, 10);
      }
    }
  },
  computed: {
    routeParameter: function() {
      if (this.view === "topic") {
        return this.$route.params.topicID;
      } else {
        return this.$route.params.partyID;
      }
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    this.updateWords();

    // let self = this;
    // axios
    //   .get("/static/overview.json")
    //   .then(function(response) {
    //     self.topWords = response.data.SectionOverviewTop5Words.govPeriods;

    //     let words = [];
    //     self.topWords[self.selectedPeriod].forEach(function(i, index) {
    //       if (i.topicName == self.topicName) {
    //         words = i.Words;
    //       };
    //       return words;
    //     });
    //     self.selectedTopWords = words;
    //     self.gotData = true;
    //   });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.setPositions();
    window.addEventListener("resize", this.setPositions);
    //window.setInterval(this.updatePlaceholder, 3000);
    this.timer = window.setTimeout(this.updatePlaceholder, 3000);
  },
  watch: {
    routeParameter: function() {
      clearTimeout(this.timer);
      this.updateWords();
      this.updatePlaceholder();
    },
    selectedPeriod: function(event) {
      this.selectedPeriod = event;
      clearTimeout(this.timer);
      this.updateWords();
      this.updatePlaceholder();
      // let self = this;
      // let words = [];
      // self.searchwords[self.selectedPeriod].forEach(function(i, index) {
      //   if (i.topicName == self.topicName) {
      //     words = i.Words;
      //   };
      //   return words;
      // });
      // self.selectedTopWords = words;
    },
    tags: function() {
      this.$emit("selectedTags", this.tags);
      this.$emit("userSearch", this.userSearch);
    },
    scrollPosition: function() {
      if (
        this.wordSearchContainerPosition &&
        this.scrollPosition >= this.wordSearchContainerPosition
      ) {
        if (this.windowWidth >= 1100) {
          this.stickyWordSearchContainer = true;
        } else {
          this.stickyWordSearchContainer = false;
        }
      } else {
        this.stickyWordSearchContainer = false;
      }
    },
    wordSearchContainerPosition: function() {
      if (
        this.wordSearchContainerPosition &&
        this.scrollPosition >= this.wordSearchContainerPosition
      ) {
        if (this.windowWidth >= 1100) {
          this.stickyWordSearchContainer = true;
        } else {
          this.stickyWordSearchContainer = false;
        }
      } else {
        this.stickyWordSearchContainer = false;
      }
    }
  },
  beforeDestroy() {
    // this.$eventBus.$off("selectedPeriod");
    clearTimeout(this.timer);
  }
};
</script>

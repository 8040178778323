<template>
  <Section>
    <div class="outer-container" id="topic-intro-container">
      <div class="title-area">
        <div class="title-card">
          <h1 class="title">
            <template v-if="view === 'topic'">
              {{topicName}}
            </template>
            <template v-else>
              {{parties[topicName].name}}
            </template>
          </h1>
        </div>
      </div>
      <div class="content-area">
        <div class="text-container">
          <template v-if="view === 'topic'">
            <TopicAbout :topicName="topicName"></TopicAbout>
          </template>
          <template v-else>
            <PartyAbout :topicName="topicName"></PartyAbout>
          </template>
        </div>
      </div>
      <div class="help-area">
        <div class="help-container">
          <div class="help-text">
            <p>Du kan vælge mellem de sidste fire regeringsperioder eller kigge på alle perioder samtidig. Hver periodeknap viser dig, hvem der var i regering i denne periode. Hver gang du skifter periode, opdateres hele siden med data for den valgte periode.</p>
          </div>
        </div>
      </div>
      <div class="timeline-area">
        <div class="period-selector-container" :class="stickyPeriods?'sticky':''">
          <PeriodSelector :stickyPeriods="stickyPeriods" :topicName="topicName" :view="view" />
        </div>
      </div>

      <div class="topic-menu" :class="topicMenu?'visible':''">
        <h4 class="topic-name" @click="showTopMenu = !showTopMenu">
          <template v-if=" view==='topic'">
            {{topicName.toUpperCase()}}
          </template>
          <template v-else>
            {{parties[topicName].name.toUpperCase()}}
          </template>
          <span class="menu-arrow"><img :src="require('@/assets/icons/down-black.svg')" alt=""></span>
        </h4>
        <transition name="fade">
          <ul class="options-menu options-menu-parties" v-if="view === 'party' && showTopMenu">
            <template v-for="(option, partyID, index) in options">
              <h4 class="topic-name option" :class="$route.params.partyID === partyID?'option-highlight':''" :key="index" @click="selectOption(partyID)">{{option.name.toUpperCase()}}</h4>
            </template>
          </ul>
          <ul class="options-menu options-menu-topics" v-if="view === 'topic' && showTopMenu">
            <template v-for="(option, key, index) in options">
              <h4 class="topic-name option" :class="topicName === option.topicName?'option-highlight':''" :key="index" @click="selectOption(option.topicID)">{{option.topicName.toUpperCase()}}</h4>
            </template>
          </ul>
        </transition>
        <ul class="smooth-links">
          <li v-if="view === 'topic'" @click="smoothScrollTo('#section-speakers')">INTRO</li>
          <li v-else @click="smoothScrollTo('#section-party-intro')">INTRO</li>

          <li @click="smoothScrollTo('#section-intro-voting')">SÅDAN STEMMER DE</li>
          <li @click="smoothScrollTo('#section-intro-speeches')">SÅDAN TALER DE</li>
        </ul>
      </div>
    </div>

  </Section>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.topic-menu {
  opacity: 0;
  transition: 0.3s;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 64.4%;
  height: 60px;
  z-index: 99999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
  &.visible {
    opacity: 1;
  }
  h4.topic-name {
    cursor: pointer;
    font-family: "futura-pt-condensed";
    font-weight: 800;
    font-style: italic;
    font-size: 20px;
    line-height: 20px;
    margin: 0;
    margin-right: 10px;
    padding: 4px;
    white-space: nowrap;
    box-sizing: border-box;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    .menu-arrow {
      position: absolute;
      height: 20px;
      margin-left: 9px;
      img {
        height: 100%;
      }
    }
  }
  ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      margin-right: 6px;
      font-family: "futura-pt";
      font-weight: 500;
      text-transform: uppercase;
      font-size: 14px;
      cursor: pointer;
      padding: 4px;
    }
    li:hover,
    li.active {
      background: #000;
      color: #fff;
    }
  }
}
ul.options-menu {
  position: absolute;
  top: 105%;
  left: -5px;
  height: 700%;
  width: 25%;
  padding: 7px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: 2px 2px 2px $color-grey;
  .option {
    height: calc(100% / 9);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    transition: 0.2s;
    cursor: pointer;
    overflow: hidden;
    white-space: wrap;
    font-size: 17px;
    padding-left: 5px;
    box-sizing: border-box;
    &:hover {
      background: $color-lightgrey;
    }
    &.option-highlight {
      background: $color-yellow;
      color: $color-red;
    }
  }
  &.options-menu-topics {
    width: 100%;
    left: 0;
    .option {
      width: calc(100% / 3);
      height: calc(100% / 8);
    }
  }
}
@media (max-width: $bp-narrow) {
  .topic-menu {
    justify-content: center;
    .topic-name {
      font-size: 125% !important;
    }
    .smooth-links {
      display: none;
    }
    .options-menu {
      height: auto;
      width: 100% !important;
      left: 50% !important;
      transform: translateX(-50%);
      .option {
        height: 35px;
      }
      &.options-menu-topics {
        width: 90vw !important;
        left: 50% !important;
        height: 400px;
        transform: translateX(-50%);
        .option {
          width: 50%;
          height: calc(100% / 12);
          font-size: 130% !important;
        }
      }
    }
  }
  .topic-menu-mobile {
    // display: flex !important;
  }
}

.outer-container {
  width: 100%;
  min-height: 50vw;
  height: auto;
  background: $color-yellow;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: $bp-narrow) {
  .outer-container {
    width: 100%;
    height: auto;
  }
}
.title-area {
  margin-top: 90px;
  margin-bottom: 30px;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.title-card {
  padding: 0 3%;
  // background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  // box-shadow: 7px 7px 0px $color-grey;
  h1.title {
    font-size: 350%;
    font-style: italic;
    color: $color-red;
  }
}
.content-area {
  height: auto;
  width: 65%;
  .text-container {
    padding: 0 16px;
    box-sizing: border-box;
  }
}
.help-area {
  height: auto;
  width: 65%;
  // background: pink;
  .help-container {
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-end;
    .help-title-card {
      width: 35%;
      // background: white;
      // box-shadow: 7px 7px 0px $color-grey;
      padding: 1% 2%;
      box-sizing: border-box;
      margin: 2% 0 1.5% 0;
      h3 {
        margin: 0;
        font-family: "futura-pt", sans-serif;
        font-weight: 700;
        font-size: 110%;
        text-align: left;
      }
    }
    .help-text {
      // background: white;
      width: 100%;
      padding: 0px 16px;
      box-sizing: border-box;
      // color: #fff;
      p {
        font-family: "roboto-condensed";
        font-weight: 500;
        font-size: 120%;
        color: #000;
        text-align: justify;
      }
    }
  }
}
.timeline-area {
  margin-top: 2%;
  position: relative;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // background: purple;
  .period-selector-container {
    height: 80px;
    width: 70%;
    // box-shadow: 7px 7px 0px $color-grey;
    background: $color-lightgrey;
    background: white;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
  }
  .timeline-overlay {
    position: absolute;
    height: 80px;
    width: 70%;
    pointer-events: none;
    transition: 0.2s;
    z-index: 9999;
    p {
      font-size: 10px;
      font-weight: 300;
      font-style: italic;
      padding: 2px 5px;
      margin: 0;
    }
    .buttons-container {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      .buttons-arrow-1 {
        position: absolute;
        height: 32px;
        transform: rotate(125deg);
        right: 110%;
        top: -4px;
      }
      .buttons-arrow-2 {
        position: absolute;
        height: 24px;
        transform: scaleX(-1) rotate(16deg);
        right: 105%;
        top: 5px;
      }
      .buttons-arrow-3 {
        position: absolute;
        height: 24px;
        transform: rotate(16deg);
        left: 105%;
        top: 5px;
      }
      .buttons-arrow-4 {
        position: absolute;
        height: 32px;
        transform: scaleX(-1) rotate(125deg);
        left: 110%;
        top: -4px;
      }
    }
    .govs-container {
      position: absolute;
      top: 62px;
      left: 21.5%;
      .govs-arrow {
        position: absolute;
        height: 25px;
        transform: scaleX(-1) rotate(-69deg);
        right: 105%;
        bottom: 12%;
      }
    }
    .all-periods-container {
      position: absolute;
      left: 86.4%;
      top: 50%;
      text-align: left;
      transform: translateY(-50%);
      top: 50%;
      .all-periods-arrow {
        height: 20px;
        transform: rotate(180deg);
      }
      .all-periods-text {
        position: absolute;
        height: 40px;
        left: 100%;
        top: 50%;
        width: 80px;
        transform: translateY(-61%);
      }
    }
    .all-periods-text {
      left: 89%;
    }
  }
}
@media (max-width: $bp-narrow) {
  .title-area {
    margin-top: 30px;
  }
  .content-area,
  .help-area {
    width: 90%;
    height: auto;
  }

  .timeline-area {
    .period-selector-container {
      width: 98%;
      height: 50px !important;
    }
  }
  .help-text {
    p {
      font-size: 150% !important;
    }
  }
}
.sticky {
  position: fixed;
  top: 60px;
  padding: 0 15%;
  &.period-selector-container {
    box-shadow: none;
    height: 40px;
    background: darken($color-lightgrey, 5%);
  }
}
</style>

<script>
import Section from "@/components/Section";
import PeriodSelector from "@/components/PeriodSelector";
import TopicAbout from "./TopicAbout";
import PartyAbout from "./PartyAbout";
import parties from "@/assets/parties/parties.json";

export default {
  props: ["topicName", "view", "topicsList"],
  components: {
    Section,
    PeriodSelector,
    TopicAbout,
    PartyAbout
  },
  data() {
    return {
      parties,
      stickyPeriods: false,
      periodSelectorPosition: null,
      scrollPosition: 0,
      topicMenu: true,
      showTopMenu: false,
      timeline: 0,
      container: 0
    };
  },
  methods: {
    handleScroll: function() {
      this.scrollPosition = window.scrollY;
    },
    smoothScrollTo: function(target) {
      let header = 100;
      let scrollY = document.querySelector(target).offsetTop - header;
      window.scroll({ top: scrollY, behavior: "smooth" });
    },
    setPositions: function() {
      this.periodSelectorPosition =
        document.querySelector(".timeline-area").getBoundingClientRect().top -
        document.querySelector("#topic-intro-container").getBoundingClientRect()
          .top;
      this.timeline = document
        .querySelector(".timeline-area")
        .getBoundingClientRect().top;
      this.container = document
        .querySelector("#topic-intro-container")
        .getBoundingClientRect().top;
      // document.querySelector(".timeline-area").top;
      // 60;
    },
    selectOption: function(option) {
      this.showTopMenu = false;
      if (this.view === "topic") {
        // console.log(option);
        this.$router.push({ params: { topicID: option } });
      } else {
        // console.log(option);
        this.$router.push({ params: { partyID: option } });
      }
    }
  },
  computed: {
    options: function() {
      if (this.view === "party") {
        return this.parties;
      } else {
        return this.topicsList;
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.setPositions();
    window.addEventListener("resize", this.setPositions);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.setPositions);
  },
  updated() {
    this.setPositions();
  },
  watch: {
    scrollPosition: function() {
      if (
        this.periodSelectorPosition &&
        this.scrollPosition >= this.periodSelectorPosition
      ) {
        this.stickyPeriods = true;
      } else {
        this.stickyPeriods = false;
      }
    }
  }
};
</script>

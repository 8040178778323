<template>
  <Section>
    <div class="outer-container">
      <div class="inner-container">
          <div class="text-container">
            <h1>Kan du tælle til 90?</h1>
            <p>Det spørgsmål stilles ofte og ikke uden grund. For hvis et eller flere partier kan samle 90 ud af de 179 mandater, så har de flertal og kan danne en regering. En regering kan dog godt være en mindretalsregering. Ved valget i 2011 dannede Socialdemokratiet en regering med Radikale Venstre og SF, på trods af at de tilsammen kun havde 77 mandater. Men med støtte fra Enhedslisten og mandater fra Grønland og Færøerne fik de 90 mandater og kunne danne regering. </p>
            <p>Det er også vigtigt at kunne tælle til 90 i det daglige politiske arbejde. For hvis regeringen ikke kan få flertal bag sine lovforslag, så kan den ikke gennemføre sin politik. Regeringspartierne stemmer stort set altid det samme. Men de skal ofte arbejde hårdt for at få støttepartierne og oppositionen til at bakke op om deres lovforslag. </p>
            <template v-if="view === 'topic'">
              <p>Der gemmer sig mange spændende historier i partiernes stemmemønstre i Folketinget. Hvem stemmer Socialdemokratiet ofte sammen med? Hvem er Dansk Folkepartis bedste venner, og hvem er de mest uvenner med? Hvem danner regeringen ofte alliancer med – og er der forskel på de forskellige politiske områder?</p>
              <p>Gå på opdagelse i de forskellige partiers stemmemønstre på de næste sider.</p>
            </template>
            <template v-else>
              <p>Gå på opdagelse i de forskellige partiers stemmemønstre på de næste sider.</p>
            </template>
          </div>
          <div class="illustration">
            <img :src="illustration" />
          </div>
      </div>
    </div>  
  </Section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.outer-container {
  height: 100%;
  min-height: calc(100vh - 80px);
  width: 100%;
  background: $color-lightgrey;
  display: flex;
  text-align: left;
  justify-content: center;
}
.inner-container {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 5% 0 5% 0;
  h1 {
    font-family: "futura-pt-bold";
    font-weight: 700;
    text-transform: uppercase;
    font-size: 300%;
    margin: 0;
    color: #000;
    margin-bottom: 20px;
    text-align: left;
  }
  p {
    font-family: "roboto-condensed";
    font-weight: 500;
    font-size: 150%;
    line-height: 1.5em;
    color: #000;
  }
}
.text-container {
  width: 50%;
}
.illustration {
  width: 50%;
  text-align: center;
  img {
    width: 80%;
  }
}
@media (max-width: $bp-narrow) {
  .inner-container {
    width: 100%;
    flex-direction: column;
    padding: 0 5% 0 5%;
  }
  .text-container {
    width: 100%;
  }
  .illustration {
    width: 100%;
  }
}
span.inverse {
  background: #000;
  color: $color-lightgrey;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import Section from "@/components/Section";
export default {
  props: ["topicName", "view"],
  components: {
    Section
  },
  computed: {
    illustration: function() {
      return require("@/assets/icons/intro-vote.svg");
    }
  }
};
</script>

<template>
  <div v-if="queryResult !== null">
    <h3>Seneste taler hvor {{keywords.toLowerCase()}} nævnes <span v-if="view === 'party'">af politikere fra {{parties[partyID].name}} </span></h3>
    <template v-for="(speech, index) in queryResult.response">
      <div class="speech" :class="`speech-${index+1}`" :key="index">
        <div class="left-column">
          <img class="speaker-image" :src="getStaticPoliticianImg(queryResult.response[index].person_id)" alt="" />
          <h4 class="speech-speaker">{{queryResult.response[index].person_name.toUpperCase()}}</h4>
          <span class="party-info">{{queryResult.response[index].party_name.toUpperCase()}} ({{queryResult.response[index].party_system_abbreviation}})</span>
          <br /><br />

          <!-- @click="selectPolitician(queryResult.response[index].person_id, queryResult.response[index].person_name, queryResult.response[index].party_system_abbreviation)" -->

          <!-- <div class="topic">
                <li v-for="item in getTopics(queryResult.response[index].speech_id)" class="topic-list">
                <a>{{item}}</a>
                </li>
            </div> -->

          <span v-if="getLawTitle(queryResult.response[index].speech_id)" class="law-title">
            <b>Sag:</b> {{getLawTitle(queryResult.response[index].speech_id)}}
          </span>
          <br />
          <span class="date"><b>Mødedato:</b> {{queryResult.response[index].meeting_date.split("T")[0]}}</span>

        </div>

        <div class="right-column">
          <p class="speech-content">
            <Highlighter class="my-highlight speech-first" highlightClassName="highlight" :searchWords="keywordsHighlight" :autoEscape="false" :caseSensitive="false" :textToHighlight="queryResult.response[index].content.slice(0,800)" />

            <Highlighter v-if="queryResult.response[index].content.length > 800" class="my-highlight speech-second" style="display:none;" highlightClassName="highlight" :searchWords="keywordsHighlight" :autoEscape="false" :caseSensitive="false" :textToHighlight="queryResult.response[index].content.slice(800,queryResult.response[index].content.length)" />

            <span class="hint" v-if="queryResult.response[index].content.length > 800">... </span>
            <br />
            <button :class="queryResult.response[index].content.length < 800?'hidden':''" class="read-more" ref="readMore" @click="readMore(index)">Vis</button>
          </p>
        </div>
      </div>
    </template>
    <PoliticianInfo :speaker="selectedSpeaker" :showPoliticianInfo="showPoliticianInfo" @closePolitician="showPoliticianInfo = false" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.speech {
  display: flex;
  text-align: left;
  margin-bottom: 40px;
  .left-column {
    width: 30%;
    align-self: flex-start;
  }
  .right-column {
    width: 80%;
    align-self: flex-start;
    padding-left: 40px;
    .speech-second {
      animation: slide-down 1s ease-out;
    }
    .add-transition {
      animation: slide-down 3s ease-out;
    }
    .read-more {
      margin-top: 10px;
      background-color: #3c5fd7;
      color: #fff;
      font-family: "futura-pt-condensed";
      font-weight: 700;
      font-size: 1.1rem;
      text-transform: uppercase;
      border-radius: 2px !important;
      border: none;
      padding: 5px 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
    }
    .read-more.hidden {
      display: none;
    }
    @keyframes slide-down {
      0% {
        opacity: 0;
        transform: translateY(-100%);
      }
      100% {
        opacity: 1;
        transform: translateY(0%);
      }
    }
  }
  h4.speech-speaker {
    font-family: "futura-pt-condensed";
    font-weight: 700;
    font-size: 140%;
    // line-height: 0.5rem;
    padding: 0;
    margin: 0;
    // cursor: pointer;
  }
  p {
    padding: 0;
    margin: 0;
    font-size: 90%;
  }
  span.party-info {
    font-family: "futura-pt-condensed";
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
    // cursor: pointer;
  }
  span.date {
    font-family: "Roboto";
    color: #000;
    font-size: 10px;
  }
  .topic {
    padding-left: 12px;
    .topic-list {
      list-style: none;
      a {
        font-family: "futura-pt-condensed";
        font-weight: 700;
        font-size: 14px;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        position: relative;
        margin: 0 16px 8px 0;
        padding: 0 10px 0 12px;
        background: #3243a5;
        -webkit-border-bottom-right-radius: 3px;
        border-bottom-right-radius: 3px;
        -webkit-border-top-right-radius: 3px;
        border-top-right-radius: 3px;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        color: #fff;
      }
      a:before {
        content: "";
        position: absolute;
        top: 0;
        left: -12px;
        width: 0;
        height: 0;
        border-color: transparent #3243a5 transparent transparent;
        border-style: solid;
        border-width: 12px 12px 12px 0;
      }
      a:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 1px;
        float: left;
        width: 5px;
        height: 5px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background: #fff;
        -webkit-box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
        box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
      }
    }
  }
  span.law-title {
    font-family: "Roboto";
    color: #000;
    font-size: 12px;
  }
}
.highlight {
  padding: 0.2em;
}
.show-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hide-speaker {
  opacity: 0.3;
}
.party-logo {
  width: 30px;
  height: 30px;
}
.speaker-image {
  width: 80%;
  height: auto;
  // cursor: pointer;
}
</style>

<script>
import globalConfig from "@/assets/globalConfig.json";
import Highlighter from "vue-highlight-words";
import axios from "axios";
import parties from "@/assets/parties/parties.json";
import PoliticianInfo from "@/components/PoliticianInfo";

export default {
  props: ["selectedTags", "searchSetting", "view", "partyID"],
  components: {
    Highlighter,
    PoliticianInfo
  },
  data() {
    return {
      globalConfig,
      parties,
      visibleCard: null,
      selectedSpeaker: null,
      selectedParty: null,
      showPoliticianInfo: false,
      query: null,
      queryResult: null,
      speechDetails: null,
      loadingSpeeches: false
    };
  },
  methods: {
    selectPolitician: function(id, name, party) {
      // console.log("Clicked: ", id, name);
      this.selectedSpeaker = {
        partyID: party,
        politician: name,
        politicianID: id,
        politicianPhoto: ""
      };
      this.showPoliticianInfo = true;
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    },
    showInfoCard: function(index) {
      this.visibleCard = index;
    },
    hideInfoCard: function() {
      this.visibleCard = null;
    },
    infoCardClass: function(index) {
      if (this.visibleCard === index) {
        return "show-card";
      } else {
        return "";
      }
    },
    hideSpeakerClass: function(index) {
      if (this.visibleCard && this.visibleCard !== index) {
        return "hide-speaker";
      } else {
        return "";
      }
    },
    arrayToStringUrl: function(arr) {
      let str = "";
      arr.forEach(function(i, index) {
        str += i.text;
        if (index != arr.length - 1) {
          str += " ";
        }
      });
      return str;
    },
    arrayToString: function(arr) {
      let str = "";
      arr.forEach(function(i, index) {
        str += i;
        if (index != arr.length - 1) {
          str += ", ";
        }
      });
      return str;
    },
    stringToArray(s, sep) {
      return s.split(sep);
    },
    getSpeeches: function() {
      let self = this;
      this.loadingSpeeches = true;
      let url =
        this.view === "topic"
          ? globalConfig.apiBaseUrl + "/search/match/"
          : globalConfig.apiBaseUrl +
            "/party/match/speeches/" +
            this.partyID +
            "/";
      if (this.selectedTags.length >= 1) {
        if (this.selectedTags.length > 1) {
          this.query = self.arrayToStringUrl(this.selectedTags);
        } else {
          this.query = this.selectedTags[0].text;
        }

        if (this.searchSetting) {
          url = globalConfig.apiBaseUrl + "/search/exact_match/";
        }

        axios
          .get(url + self.query)
          .then(response => {
            self.queryResult = response.data;
          })
          .then(function() {
            let speech_ids = [];
            for (var item of self.queryResult.response) {
              speech_ids.push(item.speech_id);
            }

            let speech_url =
              globalConfig.apiBaseUrl +
              "/search/get_multiple/" +
              self.arrayToString(speech_ids);
            axios
              .get(speech_url)
              .then(response => (self.speechDetails = response.data))
              .then(function() {
                self.loadingSpeeches = false;
              });
          });
      } else {
        self.queryResult = null;
      }
    },
    getSpeechDetails: function(array, key, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
          return array[i];
        }
      }
      return null;
    },
    getTopics: function(speech_id) {
      let topics = this.getSpeechDetails(
        this.speechDetails.response,
        "speech_id",
        speech_id
      ).topic_name;
      if (topics !== null) {
        return this.stringToArray(topics, ";");
      }
    },
    getLawTitle: function(speech_id) {
      if (this.speechDetails) {
        let details = this.getSpeechDetails(
          this.speechDetails.response,
          "speech_id",
          speech_id
        );
        return details.titelkort;
      } else {
        return null;
      }
    },
    getStaticPoliticianImg: function(id) {
      let url =
        globalConfig.staticAssetsCDN +
        "/images/" +
        "politicianID_" +
        id +
        "_size_240.jpg";
      return url;
    },
    readMore: function(index) {
      let b = this.$refs.readMore[index];
      let speech = b.parentNode.querySelector(".speech-second");

      if (speech.style.display === "none") {
        speech.style.display = "inline";
        //speech.classList.add("add-transition")
        this.$refs.readMore[index].parentNode.querySelector(
          ".hint"
        ).style.display = "none";
        b.innerHTML = "Skjul";
      } else {
        speech.style.display = "none";
        //speech.classList.remove("add-transition")
        this.$refs.readMore[index].parentNode.querySelector(
          ".hint"
        ).style.display = "inline";
        b.innerHTML = "Vis";
      }
    }
  },
  computed: {
    keywordsHighlight() {
      let k = [];
      this.selectedTags.forEach(function(i) {
        k.push("\\b" + i.text + "\\b");
      });
      return k;
    },
    keywords() {
      let k = [];
      this.selectedTags.forEach(function(i) {
        k.push(i.text);
      });
      return '"' + this.arrayToString(k) + '"';
    }
  },
  watch: {
    selectedTags: function() {
      this.getSpeeches();
    }
  },
  created: function() {
    // Don't know if this is needed?
    if (!this.loadSpeeches) {
      this.getSpeeches();
      this.loadSpeeches = true;
    }
  }
};
</script>
